/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Avatar_1,
  sidebar_icon_01,
  sidebar_icon_02,
  sidebar_icon_03,
  sidebar_icon_04,
  sidebar_icon_05,
  sidebar_icon_06,
  sidebar_icon_07,
  sidebar_icon_08,
  sidebar_icon_09,
  sidebar_icon_10,
  verified_badge,
  logo_icon_01,
} from "../../imagepath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faKey, faMoneyBill, faPassport, faRightFromBracket, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import TokenManager from "../../../../Apis/TokenManager";
import { Link, useHistory } from "react-router-dom";
import AuthApi from "../../../../Apis/AuthApi";
import { toast } from "react-toastify";

const Sidebar = () => {
  const history = useHistory(); 
  const claims = TokenManager.getClaims(); 
  const [user, setUser] = useState();
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isFavourities, setIsFavourities] = useState(false);
  const [isSettings, setIsSettings] = useState(false);

  const getUserDetails = async () => {
    const response = await AuthApi.getUser(claims.sub);
    if (response.success) {
      setUser(response.data);
    } else {
      toast.error("something went wrong");
    }
  }; 

  useEffect(() => {
    getUserDetails();
  }, []);

  const toggleHighlight = () => {
    setIsHighlighted((prevState) => !prevState);
  };

  const toggleFavourities = () => {
    setIsFavourities((prevState) => !prevState);
  };

  const toggleSettings = () => {
    setIsSettings((prevState) => !prevState);
  };

  const handleLogout=()=>{
    TokenManager.clear()  
    history.push("/index")
  }

  return (
    <>
      <div className="settings-widget">
        <div className="settings-header d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center">
          <Link to="/account-details">
            <img
              alt="profile image"
              src={user?.image || Avatar_1}
              className="avatar-lg rounded-circle"
            />
          </Link>
          <div className="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
            <h3 className="mb-0">
              <Link to="/account-details">{user?.businessName}</Link>
                {user?.status && <img src={verified_badge} className="ms-1" alt="Img" />}
            </h3>
            {/* <p className="mb-0">@brucebush</p> */}
          </div>
        </div>
        <div className="settings-menu">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              {/* <li className="nav-item">
          <Link to="/freelancer-dashboard" className="nav-link active">
            <img src={sidebar_icon_01} alt="Img" /> Dashboard
          </Link>
        </li> */}
              {/* <li className="nav-item submenu">
          <Link to="#" className={`${isHighlighted ? 'nav-link subdrop' : 'nav-link'}`} onClick={toggleHighlight}>
            <img src={sidebar_icon_02} alt="Img" /> Posts & Requests
            <span className="menu-arrow" />
          </Link>
          <ul className="sub-menu-ul" style={{ display: isHighlighted ? 'block' : 'none' }}>
            <li>
              <Link to="/freelancer-project-proposals">My Proposal</Link>
            </li>
            <li>
              <Link to="/freelancer-ongoing-projects">Ongoing Projects</Link>
            </li>
            <li>
              <Link to="/freelancer-completed-projects">Completed Projects</Link>
            </li>
            <li>
              <Link to="/freelancer-cancelled-projects">Cancelled Projects</Link>
            </li>
          </ul>
        </li> */}
              {/* <li className="nav-item submenu">
          <Link to="#"  className={`${isFavourities ? 'nav-link subdrop' : 'nav-link'}`} onClick={toggleFavourities}>
            <img src={sidebar_icon_03} alt="Img" /> Favourites
            <span className="menu-arrow" />
          </Link>
          <ul className="sub-menu-ul" style={{ display: isFavourities ? 'block' : 'none' }} >
            <li>
              <Link to="/freelancer-favourites">Bookmarked Projects</Link>
            </li>
            <li>
              <Link to="/freelancer-invitations">Invitations</Link>
            </li>
          </ul>
        </li> */}
              {/* <li className="nav-item">
          <Link to="/freelancer-review" className="nav-link">
            <img src={sidebar_icon_04} alt="Img" /> Reviews
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/freelancer-portfolio" className="nav-link">
            <img src={sidebar_icon_05} alt="Img" /> Portfolio
          </Link>
        </li> */}

              {/* <li className="nav-item">
          <Link to="/freelancer-chats" className="nav-link">
            <img src={sidebar_icon_06} alt="Img" /> Chat
          </Link>
        </li> */}
              {/* <li className="nav-item">
          <Link to="/freelancer-withdraw-money" className="nav-link">
            <img src={sidebar_icon_07} alt="Img" /> Payments
          </Link>
        </li> */}
              {/* <li className="nav-item">
          <Link to="/freelancer-payout" className="nav-link">
            <img src={sidebar_icon_08} alt="Img" /> Payout
          </Link>
        </li> */}
              {/* <li className="nav-item">
          <Link to="/freelancer-withdraw-money" className="nav-link">
            <img src={sidebar_icon_09} alt="Img" /> Statement
          </Link>
        </li> */}
              {/* <li className="nav-item">
          <Link to="#" className={`${isSettings ? 'nav-link subdrop' : 'nav-link'}`} onClick={toggleSettings}>
            <img src={sidebar_icon_10} alt="Img" />  Settings
            <span className="menu-arrow" />
          </Link>
          <ul className="sub-menu-ul" style={{ display: isSettings ? 'block' : 'none' }}>
            <li>
              <Link to="/settings">Profile Setting</Link>
            </li>
            <li>
              <Link to="/membership">Plan &amp; Billing</Link>
            </li>
            <li>
              <Link to="/verify-identity">Verify Identity</Link>
            </li>
            <li>
              <Link to="/freelancer-change-password">Changes Password</Link>
            </li>
            <li>
              <Link to="/freelancer-delete-account">Delete Account</Link>
            </li>
          </ul>
        </li> */}
              <li>
                <Link to="/settings"><FontAwesomeIcon icon={faGears} style={{paddingRight:'6px'}}/>Profile Setting</Link>
              </li>
              <li>
                <Link to="/membership"><FontAwesomeIcon icon={faMoneyBill} style={{paddingRight:'6px'}}/> Plan &amp; Billing</Link>
              </li>
              <li>
                <Link to="/verify-identity"><FontAwesomeIcon icon={faPassport} style={{paddingRight:'6px'}}/> Verify Identity</Link>
              </li>
              <li>
                <Link to="/change-password"><FontAwesomeIcon icon={faKey} style={{paddingRight:'6px'}}/> Changes Password</Link>
              </li>
              <li>
                <Link to="/delete-account"><FontAwesomeIcon icon={faTrashCan} style={{paddingRight:'6px'}}/>{" "}  Delete Account</Link>
              </li>
              <li className="nav-item">
                
                <Link to="#" onClick={handleLogout} className="nav-link">
                <FontAwesomeIcon icon={faRightFromBracket} flip="horizontal" style={{paddingLeft:'6px'}}/>{" "}  
                Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export { Sidebar };
