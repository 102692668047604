/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// eslint-disable-next-line no-unused-vars
import { fb_icon, google_icon, ios_icon, TechLogo } from "../imagepath";
import AuthApi from "../../../Apis/AuthApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faSpinner } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const history = useHistory();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateInputs = () => {
    // Check if email is valid

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email address!");
      return false;
    }

    // Check if password is empty
    if (!password) {
      toast.error("Password cannot be empty!");
      return false;
    }

    return true;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (validateInputs()) {
      setLoading(true);
      const payload = {
        email: email,
        password: password,
      };
      const response = await AuthApi.login(payload);
      console.log(response);
      if (response.success) {
        setLoading(false);
        toast.success("Welcome " + response.data.businessName);
        history.push("/posts");
      } else {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    document.body.className = "account-page";
    return () => {
      document.body.className = "";
    };
  });
  return (
    <>
      {/* Page Content */}
      <div className="login-wrapper">
        <div className="content p-0">
          {/* Login Content */}
          <div className="account-content">
            <div className="align-items-center justify-content-center">
              <div className="login-right">
                <div className="login-header text-center">
                  <Link to="/">
                    <img src={TechLogo} alt="logo" className="img-fluid" />
                  </Link>
                  <h3>Welcome! Nice to see you again</h3>
                </div>
                <form action="/dashboard">
                  <div className="input-block">
                    <label className="focus-label">
                      Email Address <span className="label-star"> *</span>
                    </label>
                    <input
                      type="email"
                      className="form-control floating"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-block">
                    <label className="">
                      Password <span className="label-star"> *</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control floating pass-input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        className="password-icon"
                        onClick={togglePasswordVisibility}
                      >
                        <span
                          className={`fas toggle-password ${passwordVisible ? "fa-eye" : "fa-eye-slash"}`}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={loading}
                    onClick={(e) => handleClick(e)}
                    className="btn btn-primary w-100 btn-lg login-btn d-flex align-items-center justify-content-center"
                  >
                    {loading ? <FontAwesomeIcon icon={faSpinner} spin />:<>Login Now                     <i className="feather-arrow-right ms-2" /></> } 

                  </button>
                  <div className="login-or">
                    <p>
                      <span>OR</span>
                    </p>
                  </div>
                  {/* <div className="row social-login">
                    <div className="col-sm-4">
                      <Link to="#" className="btn btn-block"><img src={google_icon} alt="Google" />Google</Link>
                    </div>
                    <div className="col-sm-4">
                      <Link to="#" className="btn btn-block"><img src={fb_icon} alt="Fb" />Facebook</Link>
                    </div>
                    <div className="col-sm-4">
                      <Link to="#" className="btn btn-block"><img src={ios_icon} alt="Apple" />Apple</Link>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-8 dont-have d-flex  align-items-center">
                      New to Tech for share{" "}
                      <Link to="/register" className="ms-2">
                        Signup?
                      </Link>
                    </div>
                    <div className="col-sm-4 text-sm-end">
                      <Link className="forgot-link" to="/forgot-password">
                        Lost Password?
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* /Login Content */}
        </div>
      </div>

      {/* /Page Content */}
    </>
  );
};
export default Login;
