/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from "react-router-dom";
import {icon_15}  from '../component/pages/imagepath'
function ViewDetails({selectedUser}) {
    return (
        <>
            {/* Add Modal */}
            <div className="modal fade transaction-modal" id="transaction-category">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header flex-wrap">
                            <div className="transaction-group">
                                <div className="head-transaction">
                                    <h4>User Details</h4>
                                    <p> #{selectedUser?.id}</p>
                                </div>
                                <div className="complete-btn">
                                    <Link to="#" className="btn btn-primary">
                                    {selectedUser?.status}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="table-avatar user-profile invest-acount">
                                <a href={`/company-details/${selectedUser?.id}`}>
                                    <img
                                        className="exchange case-icon"
                                        src={selectedUser?.image || icon_15}
                                        alt=""
                                    />
                                    <div>
                                        <h5>{selectedUser?.businessName}</h5>
                                        {/* <p className="receive-date">Apr 25, 2022 11:01 AM</p> */}
                                    </div>
                                </a>
                            </div>
                            <div className="acount-transaction">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="basic-info ">
                                            <h4>Details</h4>
                                            <div className="additional-info">
                                                <p> Email </p>
                                                <h5>{selectedUser?.email}</h5>
                                            </div>
                                            <div className="additional-info">
                                                <p>Phone Number </p>
                                                <h5>{selectedUser?.phoneNumber}</h5>
                                            </div>
                                            <div className="additional-info">
                                                <p> KVK </p>
                                                <h5>{selectedUser?.kvk}</h5>
                                            </div>
                                            <div className="additional-info">
                                                <p> Bussinss Name</p>
                                                <h5>{selectedUser?.businessName}</h5>
                                            </div>
                                            <div className="additional-info">
                                                <p> Description </p>
                                                <h5>{selectedUser?.description}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="basic-info">
                                            <h4>Address</h4>
                                            <div className="additional-info">
                                                <p> Street </p>
                                                <h5>{selectedUser?.address.street}</h5>
                                            </div>
                                            <div className="additional-info">
                                                <p>Post Code </p>
                                                <h5>{selectedUser?.address.postCode}</h5>
                                            </div>
                                            <div className="additional-info">
                                                <p>City</p>
                                                <h5>{selectedUser?.address.city}</h5>
                                            </div>
                                            <div className="additional-info">
                                                <p> State</p>
                                                <h5>{selectedUser?.address.state}</h5>
                                            </div>
                                            <div className="additional-info">
                                                <p> Country</p>
                                                <h5>{selectedUser?.address.country}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <div className="basic-info additional-detail top-border">
                                            <h4>Order Details</h4>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="additional-info">
                                                        <p>Order Date</p>
                                                        <h5>Mar 13, 2022</h5>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="additional-info">
                                                        <p> Placed By</p>
                                                        <h5>UID00001 </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="basic-info additional-detail">
                                            <h4 className="mt-0">Additional Details</h4>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="additional-info">
                                                        <p>Transaction Type</p>
                                                        <h5>Deposit</h5>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="additional-info">
                                                        <p> Payment Gateway</p>
                                                        <h5>Paypal </h5>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="additional-info">
                                                        <p>Payment To</p>
                                                        <h5> usdttest87i2UK3JUWzR7iy6z5eRrxbAeT</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="basic-info additional-detail">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="additional-info">
                                                        <p>Updated Balance</p>
                                                        <h5 className="active-balance">13,144.626 USD</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add Modal */}
        </>
    )
}
export default ViewDetails;