/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Img_04 } from "../../imagepath";
import { toast } from "react-toastify";
import ConversationApi from "../../../../Apis/ConversationApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faSpinner } from "@fortawesome/free-solid-svg-icons";
import messageIcon from "../../../assets/img/icon/message-chat-icon.svg";
import { useParams } from "react-router-dom";
import TokenManager from "../../../../Apis/TokenManager";
const ProfileSidebar = ({ user }) => {
  const params= useParams()
  const claims= TokenManager.getClaims()
  const pathname = window.location.pathname.split("/")[1];
  const googleMapsUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAoN3EbRtZ_tb-banToXXZyd_d-xtqgP7Y&q=${encodeURIComponent(
    `${user?.address?.street}, ${user?.address?.city}, ${user?.address?.state}, ${user?.address?.postCode}, ${user?.address?.country}`
  )}`;
  const [loading, setLoading] = useState(false);
  const [contactMessage, setContactMessage] = useState("");

  const handleContactMe = async (e) => {
    e.preventDefault();
    if (contactMessage === "") {
      toast.error("Please write a  message");
    }
    setLoading(true);
    const payload = {
      conversationId: null,
      receiverId: user.id,
      text: contactMessage,
    };
    const response = await ConversationApi.SendMessage(payload);
    if (response.success) {
      toast.success("message sent successfully");
      window.location.href = "/chats";
    }
    setLoading(false);
  };

  return (
    <>
      {/* profile Sidebar */}

      {/* About Widget */}
      <div className="pro-post widget-box about-widget profile-overview">
        <div className="profile-head">
          <h4 className="pro-title mb-0">Profile Overview</h4>
        </div>
        <ul className="latest-posts pro-content">
          <li>
            <p>Shop Name</p>
            <h6>{user?.businessName}</h6>
          </li>
          {/* <li>
                                        <p>Joined</p>
                                        <h6>02-06-2024</h6>
                                    </li> */}
          <li>
            <p>Email</p>
            <h6>{user?.email}</h6>
          </li>
          <li>
            <p>Website</p>
            <h6>www.techforshare.com</h6>
          </li>
        </ul>
        {claims.sub !== params?.id && 
                <div style={{ display: "flex" }}>
                <div className="contact-btn" style={{ width: "100%" }}>
                  <a href={`tel:${user?.phoneNumber}`} className="btn btn-primary">
                    <i className="fas fa-phone-alt" /> Call
                  </a>
                </div>
                <div className="contact-btn" style={{ width: "100%" }}>
                  <a
                    href="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#contactModal"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon icon={faComment} style={{ margin: "0 4px" }} />
                    Chat
                  </a>
                </div>
              </div>
        }

      </div>
      {/* /About Widget */}
      {/* Company Location */}
      <div className="pro-post widget-box location-widget">
        <div className="profile-head">
          <h4 className="pro-title">Company Location</h4>
        </div>
        <div className="map-location">
          <iframe
            src={googleMapsUrl}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
      {/* /Company Location */}
      {/* Working Widget */}
      {/* <div className="pro-post widget-box  working-days">
                                <div className="profile-head">
                                    <h4 className="pro-title mb-0">Working Days</h4>
                                </div>
                                <ul className="latest-posts pro-content">
                                    <li>
                                        <p>Monday</p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Tuesday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Wednesday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Thursday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Friday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Saturday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Sunday </p>
                                        <h6>
                                            <span>Close</span>
                                        </h6>
                                    </li>
                                </ul>
                            </div> */}
      {/* /Working Widget */}
      {/* Social Widget */}
      <div className="pro-post widget-box social-widget">
        <div className="profile-head">
          <h4 className="pro-title">SOCIAL LINKS</h4>
        </div>
        <ul className="social-link-profile">
          <li>
            <Link to="#">
              <i className="fab fa-facebook" />
            </Link>
          </li>
          {/* <li>
                                        <Link to="#">
                                            <i className="fab fa-twitter" />
                                        </Link>
                                    </li> */}
          <li>
            <Link to="#">
              <i className="fab fa-instagram" />
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="fab fa-linkedin" />
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="fab fa-telegram" />
            </Link>
          </li>
        </ul>
      </div>
      <div
        className="modal fade"
        id="contactModal"
        tabIndex="-1"
        aria-labelledby="fileLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h4 className="modal-title" id="fileLabel">
                Write Message
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form action="/freelancer-project-proposals">
                <div className="row">
                  <div className="col-md-12 input-block">
                    {/* <label className="form-label">Write Message</label> */}
                    {/* Assuming TextEditor is a custom component */}
                    {/* <TextEditor /> */}
                    <textarea
                      value={contactMessage}
                      onChange={(e) => setContactMessage(e.target.value)}
                      rows={5}
                      className="form-control"
                      placeholder="Enter your message"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 submit-section">
                    {/* <label className="custom_check">
                      <input type="checkbox" name="select_time" />
                      <span className="checkmark" /> I agree to the Terms And Conditions
                    </label> */}
                  </div>
                  <div className="col-md-12 submit-section text-end">
                    <Link
                      data-bs-dismiss="modal"
                      className="btn btn-cancel submit-btn"
                    >
                      Cancel
                    </Link>
                    <Link
                      onClick={handleContactMe}
                      className="btn btn-primary submit-btn"
                    >
                      {loading ? <FontAwesomeIcon icon={faSpinner} /> : "Send"}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Social Widget */}
      {/* /Profile Sidebar */}
    </>
  );
};
export { ProfileSidebar };
