/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";
import EmployerBreadcrumb from "../../foremployers/common/employerBreadcrumb";
import FreelancerSidebar from "../../foremployers/common/freelancerSidebar";
import { Avatar_1, logo_icon_01 } from "../../imagepath";
import { useCategory } from "../../Category";
import TechRequestsApi from "../../../../Apis/TechRequestsApi";
import { toast } from "react-toastify";
import getDateTime from "../../../../getDateTime";
import SubCategoryApi from "../../../../Apis/SubCategoryApi";
import AuthApi from "../../../../Apis/AuthApi";
import SkeletonRequest from "../../../SkeletonRequest.jsx";
import TokenManager from "../../../../Apis/TokenManager.js";

const ProjectList = () => {
  const claims = TokenManager.getClaims();
  const [allRequests, setAllRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getCategoryById } = useCategory();
  const [userCache, setUserCache] = useState({});
  const [user, setUser] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [requestPerPage, setRequestPerPage] = useState(10);

  const fetchRequests = async () => {
    setLoading(true);
    const response = await TechRequestsApi.getAllTechRequests();
    if (response.success) {
      const requests = response.data; // Adjust based on API response structure
      const total = response.data?.length; // Total number of posts from API response
      setAllRequests(requests);
      setFilteredRequests(requests);
      setTotalPages(Math.ceil(total / requestPerPage));
      setAllRequests(requests);
      await fetchUsers(requests);
    } else {
      toast.error("Something went wrong");
    }
    setLoading(false); // Only set loading false once at the end
  };

  const [subCategories, setSubCategories] = useState({});

  const fetchSubCategory = async (id) => {
    if (!subCategories[id]) {
      const response = await SubCategoryApi.getSubCategoryById(id);
      if (response.success) {
        setSubCategories((prev) => ({
          ...prev,
          [id]: response.data.name,
        }));
      }
    }
  };

  const fetchUsers = async (requests) => {
    const uniqueUserIds = [...new Set(requests.map((post) => post.userId))];
    const userPromises = uniqueUserIds.map((id) => getUserById(id));
    const users = await Promise.all(userPromises);

    const userCacheData = users.reduce((acc, user) => {
      if (user) {
        acc[user.id] = user;
      }
      return acc;
    }, {});

    setUserCache(userCacheData);
  };

  const getUserById = async (id) => {
    if (userCache[id]) {
      return userCache[id];
    }

    const response = await AuthApi.getUser(id);
    if (response.success) {
      return response.data;
    } else {
      toast.error("something went wrong");
      return null;
    }
  };

  const updateFilteredRequests = (requests, page) => {
    const startIndex = (page - 1) * requestPerPage;
    const endIndex = startIndex + requestPerPage;
    setFilteredRequests(requests.slice(startIndex, endIndex));
  };

  useEffect(() => {
    if (allRequests.length > 0) {
      updateFilteredRequests(allRequests, currentPage);
    }
  }, [currentPage, allRequests]);
  useEffect(() => {
    if (allRequests) {
      allRequests.forEach((request) => {
        if (request.subCategoryId) {
          fetchSubCategory(request.subCategoryId);
        }
      });
    }
  }, [allRequests]);

  useEffect(() => {
    const fetchUserAndRequests = async () => {
      const fetchedUser = await getUserById(claims.sub);
      setUser(fetchedUser);
      if (fetchedUser?.status === "Approved") {
        fetchRequests();
      }
    };
    window.scrollTo({ top: 0, behavior: "smooth" })
    fetchUserAndRequests();
  }, []);

  useEffect(() => {
    if (allRequests.length > 0) {
      setFilteredRequests(allRequests);
    }
  }, [allRequests]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  };

  return (
    <>
      <EmployerBreadcrumb title="Browse Requests" subtitle="Requests" />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <FreelancerSidebar
                  allposts={allRequests}
                  setAllPosts={setFilteredRequests}
                />
              </StickyBox>
            </div>
            {user && user?.status !== "Approved" ? (
              <div className="col-md-12 col-lg-8 col-xl-9">
                <p>your account is not approved yet</p>
              </div>
            ) : (
              <>
                <div className="col-md-12 col-lg-8 col-xl-9">
                  <div className="sort-tab develop-list-select">
                    <div className="row align-items-center">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="d-flex align-items-center">
                          <div className="freelance-view">
                            <h4>Found {filteredRequests?.length} Results</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-sm-end">
                        <ul className="list-grid d-flex align-items-center">
                          <li>
                            <Link to="/posts" className="favour">
                              <span>POSTS</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/requests" className="favour-active">
                              <span>REQUESTS</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="list-book-mark book-mark favour-book">
                    <div className="row">
                      <div className="col-lg-12">
                        {loading
                          ? [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ind) => (
                              <SkeletonRequest key={ind} />
                            ))
                          : filteredRequests?.map((request, indx) => {
                              const user = userCache[request.userId] || {};
                              return (
                                <div key={indx} className="project-list-card">
                                  <div className="company-detail-image">
                                    <img
                                      src={user.image ? user.image : Avatar_1}
                                      className="img-fluid"
                                      alt="logo"
                                    />
                                  </div>
                                  <div>
                                    <div className="company-title">
                                      <p>
                                        {getCategoryById(request.categoryId)}
                                      </p>
                                      <h4 
                                      style={{maxWidth:'400px',wordBreak:'break-all'}}
                                      >{request.title}</h4>
                                    </div>
                                    <div className="company-splits">
                                      <div>
                                        <div className="company-address">
                                          <ul>
                                            <li>
                                              <i className="feather-map-pin" />
                                              {user.address?.city},{" "}
                                              {user.address?.country}
                                            </li>
                                            <li>
                                              <i className="feather-calendar" />
                                              {getDateTime.getDate(
                                                request.createdDate
                                              )}{" "}
                                              |{" "}
                                              {getDateTime.getTime(
                                                request.createdDate
                                              )}
                                            </li>
                                            <li>
                                              <i className="feather-file-2" />
                                              {request.comments?.length}{" "}
                                              Proposal
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          className="company-description"
                                          style={{
                                            maxWidth: "400px",
                                            wordWrap: "break-word",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <p>{request.description}</p>
                                        </div>
                                        <div className="company-description">
                                          <div className="tags">
                                            <Link key={indx} to="#">
                                              <span className="badge badge-pill badge-design">
                                                {subCategories[
                                                  request.subCategoryId
                                                ] || "Loading..."}
                                              </span>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="company-split-price">
                                    <Link
                                      to={`/request-details/${request?.id}`}
                                      className="btn btn-submits"
                                    >
                                      View More
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    {allRequests?.length > 10 && (
                      <div className="row">
                        <div className="col-md-12">
                          <ul className="paginations list-pagination">
                            <li className="page-item">
                              <Link
                                to="#"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                className={currentPage === 1 ? "disabled" : ""}
                              >
                                <i className="feather-chevron-left" />
                              </Link>
                            </li>
                            {Array.from(
                              { length: totalPages },
                              (_, index) => index + 1
                            ).map((page) => (
                              <li key={page} className="page-item">
                                <Link
                                  to="#"
                                  className={
                                    page === currentPage ? "active" : ""
                                  }
                                  onClick={() => handlePageChange(page)}
                                >
                                  {page}
                                </Link>
                              </li>
                            ))}
                            <li className="page-item">
                              <Link
                                to="#"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                className={
                                  currentPage === totalPages ? "disabled" : ""
                                }
                              >
                                <i className="feather-chevron-right" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ProjectList;
