/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Owlcarousel from "react-owl-carousel";
import "boxicons/css/boxicons.css";
import "boxicons/css/boxicons.min.css";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

import {
  AvatarImg_02,
  AvatarImg_10,
  AvatarImg_01,
  emoj_icon_01,
  media_01,
  media_02,
  media_03,
  media_04,
  media_05,
  Img_05,
} from "../../imagepath";
import "../../../assets/css/chat.css";
import Scrollbars from "react-custom-scrollbars-2";
import FsLightbox from "fslightbox-react";
import ConversationApi from "../../../../Apis/ConversationApi";
import { toast } from "react-toastify";
import TokenManager from "../../../../Apis/TokenManager";
import { useConversation } from "../../../ConversationHub";
import LoadingSpinner from "../../../../LoadingSpinner";

function formatMessage(msg) {
  const replyText = "Replyed on:";

  // Check if the message contains the reply text
  if (msg.text?.includes(replyText)) {
    // Extract the params.id and reply message from the text
    const id = msg.text.split(replyText)[1].split("+")[0]?.trim();
    const replyMessage = msg.text.split("+")[1]?.trim();

    // Return the formatted message
    return [id, replyMessage];
  }

  // If no reply text, return the original message
  return msg.text;
}

const Chats = () => {
  const chatEndRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { messages } = useConversation();
  const [conversations, setConversations] = useState();
  const [selectedChat, setSelectedChat] = useState();
  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");
  const [continuationToken, setContinuationToken] = useState(null);
  const [addClass, setAddClass] = useState(false);
  const [isShow, setShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [toggler, setToggler] = useState(false);
  const claims = TokenManager.getClaims();
  const [him, setHim] = useState();
  const [me, setMe] = useState();

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const dateObj = new Date(timestamp);
      const date = dateObj.toISOString().split("T")[0];
      const time = dateObj.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      return { date, time };
    }
  };

  const handleAddClass = () => {
    setAddClass(true);
  };

  const handleRemoveClass = () => {
    setAddClass(false);
  };

  const handleShowClass = () => {
    setShow(true);
  };

  const handleShowremoveClass = () => {
    setShow(false);
  };

  const handleAddVisible = () => {
    setIsVisible(true);
  };

  const handleRemoveVisible = () => {
    setIsVisible(false);
  };
  const handleChangeSelectedChat = (selecChat) => {
    setSelectedChat(selecChat);
  };

  const galleryItems = [
    { original: media_01 },
    { original: media_02 },
    { original: media_03 },
    { original: media_04 },
  ];

  const galleryImages = [
    { original: media_01 },
    { original: media_02 },
    { original: media_03 },
    { original: media_04 },
    { original: media_05 },
  ];
  emoj_icon_01;

  const handleSendMessage = async () => {
    if (message === "") {
      toast.error("please write something to send");
      return;
    }
    const paylaod = {
      conversationId: selectedChat.id,
      receiverId: him.id,
      text: message,
    };
    const response = await ConversationApi.SendMessage(paylaod);
    if (response.success) {
      setMessage("");
      setChat((prevChat) => [...prevChat, response.data]);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the page reload
    if (message.trim()) {
      // Only send if there's a message
      handleSendMessage();
    }
  };
  const getAllConversations = async () => {
    setLoading(true);
    const response = await ConversationApi.GetAllConversations();
    if (response.success) {
      console.log("all conv", response.data);
      setConversations(response.data.reverse());
    } else {
      toast.error("could not find messages");
    }
    setLoading(false);
  };

  const getChatById = async (id) => {
    setLoading(true);
    const response = await ConversationApi.GetConversationMessages(
      continuationToken,
      id
    );
    if (response.success) {
      setChat(response.data.messages.reverse());
      setContinuationToken(response.data.continuationToken);
    } else {
      toast.error("could not find chat messages");
    }
    setLoading(false);
  };

  useEffect(() => {
    document.body.className = "chat-page main-chat-blk dashboard-page";
    return () => {
      document.body.className = "";
    };
  });

  useEffect(() => {
    getAllConversations();
  }, []);

  useEffect(() => {
    if (conversations) {
      setSelectedChat(conversations[0]);
      if (conversations[0]?.sender.id === claims.sub) {
        setHim(conversations[0]?.receiver);
        setMe(conversations[0]?.sender);
      } else {
        setHim(conversations[0]?.sender);
        setMe(conversations[0]?.receiver);
      }
    }
  }, [conversations]);

  useEffect(() => {
    if (selectedChat) {
      getChatById(selectedChat.id);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (messages && messages.id) {
      if (selectedChat?.id === messages?.data?.conversationId) {
        setChat((prevChat) => [...prevChat, messages.data]);
      }
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom(); // Scroll to the bottom when new messages are added
  }, [chat]);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {conversations && conversations.length > 0 ? (
        <div className="chat-page-wrapper">
          <div className="container">
            <div className="chat-contents">
              <div className="sidebar-group left-sidebar chat_sidebar">
                {/* Chats sidebar */}
                <div
                  id="chats"
                  className="left-sidebar-wrap sidebar active slimscroll"
                >
                  <div className="slimscroll">
                    <Scrollbars>
                      <div className="left-chat-title all-chats d-flex justify-content-between align-items-center">
                        <div className="select-group-chat">
                          <div className="dropdown">
                            <Link to="#">All Chats</Link>
                          </div>
                        </div>
                        <div className="add-section">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                className="user-chat-search-btn"
                                onClick={handleAddClass}
                              >
                                <i className="bx bx-search" />
                              </Link>
                            </li>
                            {/* <li>
                          <div className="chat-action-btns">
                            <div className="chat-action-col">
                              <Link
                                className="#"
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="bx bx-dots-vertical-rounded" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link
                                  to="#"
                                  className="dropdown-item "
                                  data-bs-toggle="modal"
                                  data-bs-target="#new-chat"
                                >
                                  <span>
                                    <i className="bx bx-message-rounded-add" />
                                  </span>
                                  New Chat{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#new-group"
                                >
                                  <span>
                                    <i className="bx bx-user-circle" />
                                  </span>
                                  Create Group
                                </Link>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#invite-other"
                                >
                                  <span>
                                    <i className="bx bx-user-plus" />
                                  </span>
                                  Invite Others
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li> */}
                          </ul>
                          {/* Chat Search */}
                          <div
                            className={
                              addClass
                                ? "user-chat-search visible-chat"
                                : "user-chat-search"
                            }
                          >
                            <form>
                              <span className="form-control-feedback">
                                <i className="bx bx-search" />
                              </span>
                              <input
                                type="text"
                                name="chat-search"
                                placeholder="Search"
                                className="form-control"
                              />
                              <div className="user-close-btn-chat">
                                <span
                                  className="material-icons"
                                  onClick={handleRemoveClass}
                                >
                                  close
                                </span>
                              </div>
                            </form>
                          </div>
                          {/* /Chat Search */}
                        </div>
                      </div>
                      {/* /Left Chat Title */}
                      {/* Top Online Contacts */}
                      {/* <div className="top-online-contacts">
                    <div className="fav-title">
                      <h6>Online Now</h6>
                    </div>
                    <div className="swiper-container">
                      <div className="swiper-wrapper">
                        <Owlcarousel
                          className="owl-theme testimonial-slider aos"
                          {...options}
                        >
                          <div className="swiper-slide">
                            <div className="top-contacts-box">
                              <div className="profile-img online">
                                <Link to="/chats">
                                  <img src={AvatarImg_02} alt="Image" />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="top-contacts-box">
                              <div className="profile-img online">
                                <Link to="/chats">
                                  <img src={AvatarImg_01} alt="Image" />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="top-contacts-box">
                              <div className="profile-img online">
                                <Link to="/chats">
                                  <img src={AvatarImg_07} alt="Image" />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="top-contacts-box">
                              <div className="profile-img online">
                                <Link to="/chats">
                                  <img src={AvatarImg_05} alt="Image" />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="top-contacts-box">
                              <div className="profile-img online">
                                <Link to="/chats">
                                  <img src={AvatarImg_03} alt="Image" />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="top-contacts-box">
                              <div className="profile-img online">
                                <Link to="/chats">
                                  <img src={AvatarImg_02} alt="Image" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Owlcarousel>
                      </div>
                    </div>
                  </div> */}
                      {/* /Top Online Contacts */}
                      <div className="sidebar-body chat-body" id="chatsidebar">
                        {/* Left Chat Title */}
                        <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
                          <div className="fav-title pin-chat">
                            <h6>
                              <i className="bx bx-message-square-dots me-1" />
                              Recent Chat
                            </h6>
                          </div>
                        </div>
                        {/* /Left Chat Title */}
                        <ul className="user-list">
                          {conversations &&
                            conversations?.length > 0 &&
                            conversations?.map((conv) => {
                              const he =
                                conv?.sender.id === claims.sub
                                  ? conv.receiver
                                  : conv.sender;
                              return (
                                <>
                                  <li
                                    key={conv.id}
                                    className="user-list-item chat-user-list "
                                  >
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        handleChangeSelectedChat(conv)
                                      }
                                      className={
                                        selectedChat?.id === conv.id &&
                                        "selectedChat"
                                      }
                                    >
                                      <div className="avatar ">
                                        {/* vatar-online */}
                                        <img
                                          src={he?.image || AvatarImg_01}
                                          className="rounded-circle"
                                          alt={he?.businessName}
                                        />
                                      </div>
                                      <div className="users-list-body">
                                        <div>
                                          <h5>{he?.businessName}</h5>
                                          <p>
                                            {
                                               (
                                                Array.isArray(formatMessage(conv.lastMessage)) ? (
                                                  <>
                                                    {formatMessage(conv.lastMessage)[1].substring(
                                                  0,
                                                  20
                                                ) + "..."}
                                                  </>
                                                ) : (
                                                  conv.lastMessage.substring(
                                                    0,
                                                    20
                                                  ) + "..."
                                                )
                                              )}
                                          </p>
                                        </div>
                                        <div className="last-chat-time">
                                          <small
                                            className={
                                              selectedChat?.id === conv.id
                                                ? "text-light"
                                                : "text-muted"
                                            }
                                          >
                                            {
                                              formatTimestamp(conv?.createdAt)
                                                ?.time
                                            }
                                          </small>
                                          {/* <div className="chat-pin">
                                        {conv.lastMessageSenderId !==
                                          claims.sub && (
                                          <span className="count-message"></span>
                                        )}
                                      </div> */}
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                </>
                              );
                            })}
                        </ul>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
                {/* / Chats sidebar */}
              </div>
              {/* Content */}

              {/* Chat */}
              <div className="chat chat-messages" id="middle">
                <div>
                  <div className="chat-header">
                    <div className="user-details">
                      <div className="d-lg-none">
                        <ul className="list-inline mt-2 me-2">
                          <li className="list-inline-item">
                            <Link
                              className="text-muted px-0 left_sides"
                              to="#"
                              data-chat="open"
                            >
                              <i className="fas fa-arrow-left" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <figure className="avatar ms-1">
                        <img
                          src={him?.image || AvatarImg_02}
                          className="rounded-circle"
                          alt={him?.businessName}
                        />
                      </figure>
                      <div className="mt-1">
                        <h5>{him?.businessName}</h5>
                        {/* <small className="last-seen">Last Seen at 07:15 PM</small> */}
                      </div>
                    </div>
                    <div className="chat-options ">
                      <ul className="list-inline">
                        {/* <li className="list-inline-item">
                      <Link
                        to="#"
                        className="btn btn-outline-light chat-search-btn"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Search"
                        onClick={handleShowClass}
                      >
                        <i className="bx bx-search" />
                      </Link>
                    </li>
                    <li
                      className="list-inline-item"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Video Call"
                    >
                      <Link
                        to="#"
                        className="btn btn-outline-light"
                        data-bs-toggle="modal"
                        data-bs-target="#video_call"
                      >
                        <i className="bx bx-video" />
                      </Link>
                    </li>
                    <li
                      className="list-inline-item"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Voice Call"
                    >
                      <Link
                        to="#"
                        className="btn btn-outline-light"
                        data-bs-toggle="modal"
                        data-bs-target="#voice_call"
                      >
                        <i className="bx bx-phone" />
                      </Link>
                    </li> */}
                        <li className="list-inline-item dream_profile_menu">
                          <Link
                            to="#"
                            className="btn btn-outline-light not-chat-user"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Contact Info"
                            onClick={handleAddVisible}
                          >
                            <i className="bx bx-info-circle" />
                          </Link>
                        </li>
                        {/* <li className="list-inline-item">
                      <Link
                        className="btn btn-outline-light no-bg"
                        to="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="bx bx-dots-vertical-rounded" />
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link to="/" className="dropdown-item ">
                          <span>
                            <i className="bx bx-x" />
                          </span>
                          Close Chat{" "}
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#mute-notification"
                        >
                          <span>
                            <i className="bx bx-volume-mute" />
                          </span>
                          Mute Notification
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#disappearing-messages"
                        >
                          <span>
                            <i className="bx bx-time-five" />
                          </span>
                          Disappearing Message
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#clear-chat"
                        >
                          <span>
                            <i className="bx bx-brush-alt" />
                          </span>
                          Clear Message
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#change-chat"
                        >
                          <span>
                            <i className="bx bx-trash" />
                          </span>
                          Delete Chat
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#report-user"
                        >
                          <span>
                            <i className="bx bx-dislike" />
                          </span>
                          Report
                        </Link>
                        <Link to="#" className="dropdown-item">
                          <span>
                            <i className="bx bx-block" />
                          </span>
                          Block
                        </Link>
                      </div>
                    </li> */}
                      </ul>
                    </div>
                    {/* Chat Search */}
                    <div
                      className={
                        isShow ? "chat-search visible-chat" : "chat-search"
                      }
                    >
                      <form>
                        <span className="form-control-feedback">
                          <i className="bx bx-search" />
                        </span>
                        <input
                          type="text"
                          name="chat-search"
                          placeholder="Search Chats"
                          className="form-control"
                        />
                        <div className="close-btn-chat">
                          <span
                            className="material-icons"
                            onClick={handleShowremoveClass}
                          >
                            close
                          </span>
                        </div>
                      </form>
                    </div>
                    {/* /Chat Search */}
                  </div>
                  <div className="chat-body chat-page-group slimscroll">
                    <div className="messages" style={{ paddingBottom: "70px" }}>
                      {chat &&
                        chat?.map((msg) => {
                          if (msg.senderId === him?.id) {
                            return (
                              <>
                                <div className="chats">
                                  <div className="chat-avatar">
                                    <img
                                      src={him?.image || AvatarImg_02}
                                      className="rounded-circle dreams_chat"
                                      alt="image"
                                    />
                                  </div>
                                  <div className="chat-content">
                                    <div className="chat-profile-name">
                                      <h6>
                                        {him?.businessName}
                                        <span>
                                          {
                                            formatTimestamp(msg?.createdAt)
                                              ?.time
                                          }
                                        </span>
                                        {/* <span className="check-star msg-star d-none">
                          <i className="bx bxs-star" />
                        </span> */}
                                      </h6>
                                      {/* <div className="chat-action-btns ms-2">
                        <div className="chat-action-col">
                          <Link
                            className="#"
                            to="#"
                            data-bs-toggle="dropdown"
                          >
                            <i className="bx bx-dots-horizontal-rounded" />
                          </Link>
                          <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                            <Link
                              to="#"
                              className="dropdown-item message-info-left"
                            >
                              <span>
                                <i className="bx bx-info-circle" />
                              </span>
                              Message Info{" "}
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item reply-button"
                            >
                              <span>
                                <i className="bx bx-share" />
                              </span>
                              Reply
                            </Link>
                            <Link to="#" className="dropdown-item">
                              <span>
                                <i className="bx bx-smile" />
                              </span>
                              React
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#forward-message"
                            >
                              <span>
                                <i className="bx bx-reply" />
                              </span>
                              Forward
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item click-star"
                            >
                              <span>
                                <i className="bx bx-star" />
                              </span>
                              <span className="star-msg">Star Message</span>
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#report-user"
                            >
                              <span>
                                <i className="bx bx-dislike" />
                              </span>
                              Report
                            </Link>
                            <Link
                              to="#"
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#delete-message"
                            >
                              <span>
                                <i className="bx bx-trash" />
                              </span>
                              Delete
                            </Link>
                          </div>
                        </div>
                      </div> */}
                                    </div>
                                    <div className="message-content">
                                      {Array.isArray(formatMessage(msg)) ? (
                                        <>
                                          <strong>Reply on:</strong>{" "}
                                          <a
                                            href={`/post-details/${formatMessage(msg)[0]}`}
                                          >
                                            click here
                                          </a>{" "}
                                          <br />
                                          {formatMessage(msg)[1]}
                                        </>
                                      ) : (
                                        msg.text
                                      )}
                                      {/* <div className="emoj-group">
                        <ul>
                          <li className="emoj-action">
                            <Link to="#">
                              <i className="bx bx-smile" />
                            </Link>
                            <div className="emoj-group-list">
                              <ul>
                                <li>
                                  <Link to="#">
                                    <img src={emoj_icon_01} alt="Icon" />
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={emoj_icon_02} alt="Icon" />
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={emoj_icon_03} alt="Icon" />
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={emoj_icon_04} alt="Icon" />
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={emoj_icon_05} alt="Icon" />
                                  </Link>
                                </li>
                                <li className="add-emoj">
                                  <Link to="#">
                                    <i className="feather-plus" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#forward-message"
                            >
                              <i className="bx bx-share" />
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className="chats chats-right">
                                  <div className="chat-content">
                                    <div className="chat-profile-name text-end">
                                      <h6>
                                        {/* <i className="bx bx-check-double me-1 inactive-check" /> */}
                                        {me?.businessName}
                                        <span>
                                          {
                                            formatTimestamp(msg?.createdAt)
                                              ?.time
                                          }
                                        </span>
                                        {/* <span className="check-star msg-star-one d-none">
                              <i className="bx bxs-star" />
                            </span> */}
                                      </h6>
                                      {/* <div className="chat-action-btns ms-2">
                            <div className="chat-action-col">
                              <Link
                                className="#"
                                to="#"
                                data-bs-toggle="dropdown"
                              >
                                <i className="bx bx-dots-horizontal-rounded" />
                              </Link>
                              <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                                <Link
                                  to="#"
                                  className="dropdown-item message-info-left"
                                >
                                  <span>
                                    <i className="bx bx-info-circle" />
                                  </span>
                                  Message Info{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="dropdown-item reply-button"
                                >
                                  <span>
                                    <i className="bx bx-share" />
                                  </span>
                                  Reply
                                </Link>
                                <Link to="#" className="dropdown-item">
                                  <span>
                                    <i className="bx bx-smile" />
                                  </span>
                                  React
                                </Link>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#forward-message"
                                >
                                  <span>
                                    <i className="bx bx-reply" />
                                  </span>
                                  Forward
                                </Link>
                                <Link
                                  to="#"
                                  className="dropdown-item click-star-one"
                                >
                                  <span>
                                    <i className="bx bx-star" />
                                  </span>
                                  <span className="star-msg-one">
                                    Star Message
                                  </span>
                                </Link>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-message"
                                >
                                  <span>
                                    <i className="bx bx-edit-alt" />
                                  </span>
                                  Edit
                                </Link>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-message"
                                >
                                  <span>
                                    <i className="bx bx-trash" />
                                  </span>
                                  Delete
                                </Link>
                              </div>
                            </div>
                          </div> */}
                                    </div>
                                    <div className="message-content ">
                                      {Array.isArray(formatMessage(msg)) ? (
                                        <>
                                          <strong>Reply on:</strong>{" "}
                                          <a
                                            href={`/post-details/${formatMessage(msg)[0]}`}
                                          >
                                            click here
                                          </a>{" "}
                                          <br />
                                          {formatMessage(msg)[1]}
                                        </>
                                      ) : (
                                        msg.text
                                      )}
                                    </div>
                                  </div>
                                  <div className="chat-avatar">
                                    <img
                                      src={me.image || AvatarImg_10}
                                      className="rounded-circle dreams_chat"
                                      alt="image"
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })}
                      {/* <div className="chats">
                    <div className="chat-avatar">
                      <img
                        src={AvatarImg_02}
                        className="rounded-circle dreams_chat"
                        alt="image"
                      />
                    </div>
                    <div className="chat-content chat-cont-type">
                      <div className="chat-profile-name chat-type-wrapper">
                        <p>Mark Villiams Typing...</p>
                      </div>
                    </div>
                  </div> */}
                      <div ref={chatEndRef} />
                    </div>
                  </div>
                </div>
                <div className="chat-footer">
                  <form onSubmit={handleSubmit}>
                    {/* <div className="smile-foot">
                  <div className="chat-action-btns">
                    <div className="chat-action-col">
                      <Link
                        className="action-circle"
                        to="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="bx bx-dots-vertical-rounded" />
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link to="#" className="dropdown-item ">
                          <span>
                            <i className="bx bx-file" />
                          </span>
                          Document
                        </Link>
                        <Link to="#" className="dropdown-item">
                          <span>
                            <i className="bx bx-camera" />
                          </span>
                          Camera
                        </Link>
                        <Link to="#" className="dropdown-item">
                          <span>
                            <i className="bx bx-image" />
                          </span>
                          Gallery
                        </Link>
                        <Link to="#" className="dropdown-item">
                          <span>
                            <i className="bx bx-volume-full" />
                          </span>
                          Audio
                        </Link>
                        <Link to="#" className="dropdown-item">
                          <span>
                            <i className="bx bx-map" />
                          </span>
                          Location
                        </Link>
                        <Link to="#" className="dropdown-item">
                          <span>
                            <i className="bx bx-user-pin" />
                          </span>
                          Contact
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="smile-foot emoj-action-foot">
                  <Link to="#" className="action-circle">
                    <i className="bx bx-smile" />
                  </Link>
                  <div className="emoj-group-list-foot down-emoji-circle">
                    <ul>
                      <li>
                        <Link to="#">
                          <img src={emoj_icon_01} alt="Icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={emoj_icon_02} alt="Icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={emoj_icon_03} alt="Icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={emoj_icon_04} alt="Icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={emoj_icon_05} alt="Icon" />
                        </Link>
                      </li>
                      <li className="add-emoj">
                        <Link to="#">
                          <i className="feather-plus" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                    {/* <div className="smile-foot">
                  <Link to="#" className="action-circle">
                    <i className="bx bx-microphone-off" />
                  </Link>
                </div> */}
                    <div className="replay-forms">
                      {/* <div className="chats forward-chat-msg reply-div d-none">
                    <div className="contact-close_call text-end">
                      <Link to="#" className="close-replay">
                        <i className="bx bx-x" />
                      </Link>
                    </div>
                    <div className="chat-avatar">
                      <img
                        src={AvatarImg_02}
                        className="rounded-circle dreams_chat"
                        alt="image"
                      />
                    </div>
                    <div className="chat-content">
                      <div className="chat-profile-name">
                        <h6>
                          Mark Villiams<span>8:16 PM</span>
                        </h6>
                        <div className="chat-action-btns ms-2">
                          <div className="chat-action-col">
                            <Link
                              className="#"
                              to="#"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bx bx-dots-horizontal-rounded" />
                            </Link>
                            <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                              <Link
                                to="#"
                                className="dropdown-item message-info-left"
                              >
                                <span>
                                  <i className="bx bx-info-circle" />
                                </span>
                                Message Info{" "}
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item reply-button"
                              >
                                <span>
                                  <i className="bx bx-share" />
                                </span>
                                Reply
                              </Link>
                              <Link to="#" className="dropdown-item">
                                <span>
                                  <i className="bx bx-smile" />
                                </span>
                                React
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#forward-message"
                              >
                                <span>
                                  <i className="bx bx-reply" />
                                </span>
                                Forward
                              </Link>
                              <Link to="#" className="dropdown-item">
                                <span>
                                  <i className="bx bx-star" />
                                </span>
                                Star Message
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#report-user"
                              >
                                <span>
                                  <i className="bx bx-dislike" />
                                </span>
                                Report
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#delete-message"
                              >
                                <span>
                                  <i className="bx bx-trash" />
                                </span>
                                Delete
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="message-content reply-content"></div>
                    </div>
                  </div> */}
                      <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="form-control chat_form"
                        placeholder="Type your message here..."
                      />
                    </div>
                    <div className="form-buttons">
                      <button
                        type="button"
                        className="btn send-btn"
                        onClick={handleSendMessage}
                      >
                        <i className="bx bx-paper-plane" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {/* /Chat */}

              <div
                className={
                  isVisible
                    ? "right-sidebar right_sidebar_profile right-side-contact show-right-sidebar"
                    : "right-sidebar right_sidebar_profile right-side-contact hide-right-sidebar"
                }
                id="middle1"
              >
                <div className="right-sidebar-wrap active">
                  <div className="slimscroll">
                    <Scrollbars>
                      <div className="left-chat-title d-flex justify-content-between align-items-center border-bottom-0">
                        <div className="fav-title mb-0">
                          <h6>Contact Info</h6>
                        </div>
                        <div className="contact-close_call text-end">
                          <Link to="#" className="close_profile close_profile4">
                            <span
                              className="material-icons"
                              onClick={handleRemoveVisible}
                            >
                              close
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="sidebar-body">
                        <div className="mt-0 right_sidebar_logo">
                          <div className="text-center right-sidebar-profile">
                            <figure className="avatar avatar-xl mb-3">
                              <img
                                src={him?.image || AvatarImg_02}
                                className="rounded-circle"
                                alt="image"
                              />
                            </figure>
                            <h5 className="profile-name">
                              {him?.businessName}
                            </h5>
                            <div className="last-seen-profile">
                              {/* <span>last seen at 07:15 PM</span> */}
                            </div>
                            {/* <div className="chat-options chat-option-profile">
                          <ul className="list-inline">
                            <li
                              className="list-inline-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Voice Call"
                            >
                              <Link
                                to="#"
                                className="btn btn-outline-light "
                                data-bs-toggle="modal"
                                data-bs-target="#voice_call"
                              >
                                <i className="bx bx-phone" />
                              </Link>
                            </li>
                            <li
                              className="list-inline-item "
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Video Call"
                            >
                              <Link
                                to="#"
                                className="btn btn-outline-light profile-open"
                                data-bs-toggle="modal"
                                data-bs-target="#voice_call"
                              >
                                <i className="bx bx-video" />
                              </Link>
                            </li>
                            <li className="list-inline-item">
                              <Link
                                to="/chats"
                                className="btn btn-outline-light"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Chat"
                              >
                                <i className="bx bx-message-square-dots" />
                              </Link>
                            </li>
                          </ul>
                        </div> */}
                          </div>
                          <div className="chat-member-details">
                            <div className="member-details">
                              <ul>
                                <li>
                                  <h5>Info</h5>
                                </li>
                                <li>
                                  <h6>Description</h6>
                                  <span>{him?.description}</span>
                                </li>
                                <li>
                                  <h6>Phone</h6>
                                  <span>{him?.phoneNumber}</span>
                                </li>
                                <li>
                                  <h6>Email Address</h6>
                                  <span>
                                    <a href={`mailto:${him?.email}`}>
                                      {him?.email}
                                    </a>
                                  </span>
                                </li>
                                {/* <li>
                              <h6>Social Media</h6>
                              <div className="social-icons ">
                                <Link to="https://www.facebook.com/">
                                  <i className="bx bxl-facebook" />
                                </Link>
                                <Link to="https://twitter.com/">
                                  <i className="bx bxl-twitter" />
                                </Link>
                                <Link to="#">
                                  <i className="bx bxl-youtube" />
                                </Link>
                                <Link to="https://in.linkedin.com/">
                                  <i className="bx bxl-linkedin" />
                                </Link>
                              </div>
                            </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="right-sidebar-head share-media">
                    <div className="share-media-blk">
                      <h5>User Parts</h5>
                      <Link to="#">View All</Link>
                    </div>
                    <div className="about-media-tabs">
                      <nav>
                        <div className="nav nav-tabs " id="nav-tab">
                          <Link
                            className="nav-item nav-link active"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            to="#info"
                          >
                            Posts
                          </Link>
                          <Link
                            className="nav-item nav-link"
                            id="nav-profile-tab1"
                            data-bs-toggle="tab"
                            to="#Participants"
                          >
                            Requests
                          </Link>
                        </div>
                      </nav>
                      <div className="tab-content pt-0" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="info">
                          <ul className="nav share-media-img mb-0">
                            {galleryItems.map((item, index) => (
                              <li key={index}>
                                <Link
                                  to="#"
                                  data-fancybox="gallery"
                                  className="fancybox"
                                >
                                  <SlideshowLightbox>
                                    <img src={item.original} alt="Img" />
                                  </SlideshowLightbox>
                                </Link>
                              </li>
                            ))} */}
                      {/* <li>
                              <Link
                                to="#"
                                data-fancybox="gallery"
                                className="fancybox"
                              >
                                <img src={media_02} alt="Img" />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-fancybox="gallery"
                                className="fancybox"
                              >
                                <img src={media_03} alt="Img" />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-fancybox="gallery"
                                className="fancybox"
                              >
                                <img src={media_04} alt="Img" />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-fancybox="gallery"
                                className="fancybox"
                              >
                                <img src={media_05} alt="Img" />
                              </Link>
                            </li>
                            <li className="blur-media">
                              <Link
                                to="#"
                                data-fancybox="gallery"
                                className="fancybox"
                              >
                                <img src={media_02} alt="Img" />
                              </Link>
                              <span>+10</span>
                            </li> */}
                      {/* </ul>
                        </div>
                        <div className="tab-pane fade" id="Participants">
                          <ul className="nav share-media-img mb-0">
                            <li>
                              <Link to="#" data-fancybox className="fancybox">
                                <img
                                  src={media_01}
                                  alt="img"
                                  onClick={() => setToggler(!toggler)}
                                />

                                <span>
                                  <i className="bx bx-play-circle" />
                                </span>
                              </Link>
                            </li>

                            <FsLightbox
                              toggler={toggler}
                              sources={[
                                "https://www.youtube.com/embed/Mj9WJJNp5wA",
                                "https://www.youtube.com/embed/Mj9WJJNp5wA",
                                "https://www.youtube.com/embed/Mj9WJJNp5wA",
                                "https://www.youtube.com/embed/Mj9WJJNp5wA",
                                "https://www.youtube.com/embed/Mj9WJJNp5wA",
                              ]}
                            />

                            <li>
                              <Link to="#" data-fancybox className="fancybox">
                                <img
                                  src={media_02}
                                  alt="img"
                                  onClick={() => setToggler(!toggler)}
                                />
                                <span>
                                  <i className="bx bx-play-circle" />
                                </span>
                              </Link>
                            </li>

                            <li>
                              <Link to="#" data-fancybox className="fancybox">
                                <img
                                  src={media_03}
                                  alt="img"
                                  onClick={() => setToggler(!toggler)}
                                />
                                <span>
                                  <i className="bx bx-play-circle" />
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" data-fancybox className="fancybox">
                                <img
                                  src={media_04}
                                  alt="img"
                                  onClick={() => setToggler(!toggler)}
                                />
                                <span>
                                  <i className="bx bx-play-circle" />
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" data-fancybox className="fancybox">
                                <img
                                  src={media_05}
                                  alt="img"
                                  onClick={() => setToggler(!toggler)}
                                />
                                <span>
                                  <i className="bx bx-play-circle" />
                                </span>
                              </Link>
                            </li>
                            <li className="blur-media">
                              <Link to="#" data-fancybox className="fancybox">
                                <img src={media_03} alt="img" />
                              </Link>
                              <span>+10</span>
                            </li>
                          </ul>
                        </div> */}
                      {/* <div className="tab-pane fade" id="media">
                          <div className="media-file">
                            <div className="media-doc-blk">
                              <span>
                                <i className="bx bxs-file-doc" />
                              </span>
                              <div className="document-detail">
                                <h6>Landing_page_V1.doc</h6>
                                <ul>
                                  <li>12 Mar 2023</li>
                                  <li>246.3 KB</li>
                                </ul>
                              </div>
                            </div>
                            <div className="media-download">
                              <Link to="#">
                                <i className="bx bx-download" />
                              </Link>
                            </div>
                          </div>
                          <div className="media-file">
                            <div className="media-doc-blk">
                              <span>
                                <i className="bx bxs-file-pdf" />
                              </span>
                              <div className="document-detail">
                                <h6>Design Guideless.pdf</h6>
                                <ul>
                                  <li>12 Mar 2023</li>
                                  <li>246.3 KB</li>
                                </ul>
                              </div>
                            </div>
                            <div className="media-download">
                              <Link to="#">
                                <i className="bx bx-download" />
                              </Link>
                            </div>
                          </div>
                          <div className="media-file">
                            <div className="media-doc-blk">
                              <span>
                                <i className="bx bxs-file" />
                              </span>
                              <div className="document-detail">
                                <h6>sample site.txt</h6>
                                <ul>
                                  <li>12 Mar 2023</li>
                                  <li>246.3 KB</li>
                                </ul>
                              </div>
                            </div>
                            <div className="media-download">
                              <Link to="#">
                                <i className="bx bx-download" />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="link">
                          <div className="media-link-grp">
                            <div className="link-img">
                              <Link to="#">
                                <img src={media_link_01} alt="Img" />
                              </Link>
                            </div>
                            <div className="media-link-detail">
                              <h6>
                                <Link to="#">Digital Marketing Guide</Link>
                              </h6>
                              <span>
                                <Link to="#">
                                  https://elements.envato.com/all-items/blog
                                </Link>
                              </span>
                            </div>
                          </div>
                          <div className="media-link-grp mb-0">
                            <div className="link-img">
                              <Link to="#">
                                <img src={media_link_02} alt="Img" />
                              </Link>
                            </div>
                            <div className="media-link-detail">
                              <h6>
                                <Link to="#">Blog Post</Link>
                              </h6>
                              <span>
                                <Link to="#">
                                  https://elements.envato.com/blog-post-TXQ5FB8
                                </Link>
                              </span>
                            </div>
                          </div>
                        </div> */}
                      {/* </div>
                    </div>
                  </div> */}

                      {/* <div className="group-comman-theme">
                    <h6>2 Group in Common</h6>
                    <ul>
                      <li>
                        <div className="avatar">
                          <img
                            src={AvatarImg_14}
                            className="rounded-circle"
                            alt="image"
                          />
                        </div>
                        <div className="theme-content">
                          <h6>Themeforest Group</h6>
                          <p>Mark Villiams, Elizabeth, Michael....</p>
                        </div>
                      </li>
                      <li>
                        <div className="avatar">
                          <img
                            src={AvatarImg_15}
                            className="rounded-circle"
                            alt="image"
                          />
                        </div>
                        <div className="theme-content">
                          <h6>Graphic Designers</h6>
                          <p>Mark Villiams, Elizabeth, Michael....</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="chat-message-grp">
                    <ul>
                      <li>
                        <Link to="#" className="star-message-left">
                          <div className="stared-group">
                            <span className="star-message">
                              {" "}
                              <i className="bx bxs-star" />
                            </span>
                            <h6>Starred Messages</h6>
                          </div>
                          <div className="count-group">
                            <span>10</span>
                            <i className="feather-chevron-right" />
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#mute-notification"
                        >
                          <div className="stared-group">
                            <span className="mute-message">
                              {" "}
                              <i className="bx bxs-microphone-off" />
                            </span>
                            <h6>Mute Notifications</h6>
                          </div>
                          <div className="count-group">
                            <i className="feather-chevron-right" />
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <div className="stared-group">
                            <span className="block-message">
                              {" "}
                              <i className="bx bx-block" />
                            </span>
                            <h6>Block User</h6>
                          </div>
                          <div className="count-group">
                            <i className="feather-chevron-right" />
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#report-user"
                        >
                          <div className="stared-group">
                            <span className="report-message">
                              {" "}
                              <i className="bx bx-dislike" />
                            </span>
                            <h6>Report User</h6>
                          </div>
                          <div className="count-group">
                            <i className="feather-chevron-right" />
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#delete-user"
                        >
                          <div className="stared-group">
                            <span className="delete-message">
                              {" "}
                              <i className="bx bx-trash" />
                            </span>
                            <h6>Delete Chat</h6>
                          </div>
                          <div className="count-group">
                            <i className="feather-chevron-right" />
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Content */}
          </div>
        </div>
      ) : (
        <h4
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          you do not have any messages yet
        </h4>
      )}

      {/* Voice Call Modal */}
      <div className="modal fade call-modal" id="voice_call">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              {/* Outgoing Call */}
              <div className="call-box incoming-box">
                <div className="call-wrapper">
                  <div className="call-inner">
                    <div className="call-user">
                      <img
                        alt="User Image"
                        src={Img_05}
                        className="call-avatar"
                      />
                      <h4>Darren Elder</h4>
                      <span>Connecting...</span>
                    </div>
                    <div className="call-items">
                      <Link
                        to="#"
                        className="btn call-item call-end"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="material-icons">call_end</i>
                      </Link>
                      <Link to="#" className="btn call-item call-start">
                        <i className="material-icons">call</i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* Outgoing Call */}
            </div>
          </div>
        </div>
      </div>
      {/* /Voice Call Modal */}

      {/* Video Call Modal */}
      <div className="modal fade call-modal" id="video_call">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              {/* Incoming Call */}
              <div className="call-box incoming-box">
                <div className="call-wrapper">
                  <div className="call-inner">
                    <div className="call-user">
                      <img
                        className="call-avatar"
                        src={Img_05}
                        alt="User Image"
                      />
                      <h4>Darren Elder</h4>
                      <span>Calling ...</span>
                    </div>
                    <div className="call-items">
                      <Link
                        to="#"
                        className="btn call-item call-end"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="material-icons">call_end</i>
                      </Link>
                      <Link to="#" className="btn call-item call-start">
                        <i className="material-icons">videocam</i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Incoming Call */}
            </div>
          </div>
        </div>
      </div>
      {/* Video Call Modal */}
    </>
  );
};
export default Chats;
