/* eslint-disable no-unused-vars */
import React from 'react';
import { default as logo } from "../src/frontend/assets/img/Mokoto2.gif"; 
const LoadingSpinner = () => {
  const spinnerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height
  };

  const imageStyle = {
    width: '500px', // Adjust the size of the spinner
    height: '500px',
  };

  return (
    <div style={spinnerStyle}>
      <img 
        src={logo} // Replace this URL with your spinner image URL
        alt="Loading..." 
        style={imageStyle}
      />
    </div>
  );
};

export default LoadingSpinner;
