/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// Import Slick Slider
// Import Images
import {
  About_img,
  About_01,
  Review_01,
  Review_02,
  Review_03,
  home_icon,
  Logo,
  About_1,
  about_us,
  about_us_1,
  great_1,
  great_2,
  great_3,
  great_4,
  abt_3,
  checks,
  count_icon_01,
  count_icon_02,
  count_icon_03,
  count_icon_04,
  job1,
  Great_icon_01,
  Great_icon_02,
  Great_icon_03,
  Great_icon_04,
} from "../imagepath";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import EmployerBreadcrumb from "../foremployers/common/employerBreadcrumb";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";

const Aboutus = () => {
  const options = {
    items: 5,
    margin: 30,
    dots: false,
    nav: true,
    smartSpeed: 2000,
    navText: [
      '<i class="fas fa-chevron-left"></i>',
      '<i class="fas fa-chevron-right"></i>',
    ],
    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      991: {
        items: 3,
      },
      1170: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      // easing: 'ease-in-out',
      offset: 200,
      once: true,
    });
  }, []);
  return (
    <>
      {/* Breadcrumb */}
      <EmployerBreadcrumb title="About Us" subtitle="About Us" />

      {/* /Breadcrumb */}
      {/* About */}
      <section className="section about">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-12 d-flex align-items-center aos"
              data-aos="fade-up"
            >
              <div className="about-content">
                <h2>About Tech For Share.</h2>
                <p>
                At TechForShare, we empower repair shops by offering a vibrant marketplace for buying, selling, and trading parts. Whether you’re searching for a specific component or have excess inventory to offer, our platform makes it easy to find exactly what you need or reach those who do.
                </p>
                <p>
                Join us at TechForShare and become part of a growing network that’s dedicated to making the repair industry more connected, efficient, and innovative. Together, we’re turning challenges into opportunities—one part at a time.
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="about-content-img aos" data-aos="zoom-in">
                <img src={about_us} className="img-fluid" alt="img" width={500}/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content-img aos" data-aos="zoom-in">
                <img src={about_us_1} className="img-fluid" alt="img" width={500}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /About */}
      {/* Counter */}
      <section className="section projects">
        <div className="container">
          <div className="row row-gap">
            <div className="col-md-12 col-sm-12 col-12 mx-auto text-center">
              <div className="section-header aos  " data-aos="fade-up">
                <h2 className="header-title">What’s great about it?</h2>
                <p>All the features of techforshare below</p>
              </div>
            </div>
            {/* Feature Item */}
            <div
              className="col-xl-3 col-md-6 aos d-flex  "
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="feature-items d-flex align-items-center justify-content-center flex-column">
                <div className="feature-icon">
                  <img src={Great_icon_01} className="img-fluid" alt="Img" />
                </div>
                <div className="feature-content course-count text-center">
                  <h3>Browse repair shops
                  </h3>
                  <p>
                  Find trusted professionals by exploring their profiles, viewing their past work, and reading reviews from other users.
                  </p>
                </div>
              </div>
            </div>
            {/* /Feature Item */}
            {/* Feature Item */}
            <div
              className="col-xl-3 col-md-6 aos d-flex  "
              data-aos="zoom-in"
              data-aos-duration={1500}
            >
              <div className="feature-items d-flex align-items-center justify-content-center flex-column">
                <div className="feature-icon">
                  <img src={Great_icon_02} className="img-fluid" alt="Img" />
                </div>
                <div className="feature-content course-count text-center">
                  <h3>Fast Bids</h3>
                  <p>
                  Get quick responses from repair shops for your part requests. 80% of requests receive quotes within 60 seconds.
                  </p>
                </div>
              </div>
            </div>
            {/* /Feature Item */}
            {/* Feature Item */}
            <div
              className="col-xl-3 col-md-6 aos d-flex  "
              data-aos="zoom-in"
              data-aos-duration={2000}
            >
              <div className="feature-items d-flex align-items-center justify-content-center flex-column">
                <div className="feature-icon">
                  <img src={Great_icon_03} className="img-fluid" alt="Img" />
                </div>
                <div className="feature-content course-count text-center">
                  <h3>Request parts</h3>
                  <p>
                  Easily request specific parts and get quotes from multiple shops quickly to find the best deal.
                  </p>
                </div>
              </div>
            </div>
            {/* /Feature Item */}
            {/* Feature Item */}
            <div
              className="col-xl-3 col-md-6 aos d-flex  "
              data-aos="zoom-in"
              data-aos-duration={2500}
            >
              <div className="feature-items d-flex align-items-center justify-content-center flex-column">
                <div className="feature-icon">
                  <img src={Great_icon_04} className="img-fluid" alt="Img" />
                </div>
                <div className="feature-content course-count text-center">
                  <h3>Browse for parts</h3>
                  <p>
                  Discover a wide range of parts available from various repair shops and compare prices to find exactly what you need.
                  </p>
                </div>
              </div>
            </div>
            {/* /Feature Item */}
          </div>
        </div>
      </section>

      {/* Review */}
      <section className="section review">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="aos aos-init " data-aos="fade-up">
                <div className="demand-professional">
                  <h2>Connecting Repair Shops for a Stronger Future</h2>
                  <p>
                  At TechForShare, we believe in the power of collaboration to drive the repair industry forward. In a field where precision and timely access to parts are critical, we’ve built a platform that bridges the gaps between repair shops.
                  </p>
                </div>
                <div className="demand-post-job align-items-start">
                  <div className="demand-post-img">
                    <img src={checks} alt="Img" className="img-fluid" />
                  </div>
                  <div className="demand-content">
                    <p>
                    Our marketplace is designed with the needs of repair shops in mind. We understand the frustrations of searching for rare or discontinued parts, and the cost implications of holding onto excess stock. 
                    </p>
                  </div>
                </div>
                <div className="demand-post-job align-items-start">
                  <div className="demand-post-img">
                    <img src={checks} alt="Img" className="img-fluid" />
                  </div>
                  <div className="demand-content">
                    <p>
                    But TechForShare is more than just a marketplace—it’s a community. Our platform hosts active forums where repair experts can ask questions, share their experiences, and offer advice. Whether you’re dealing with a particularly tricky repair or looking for insights on best practices, our community is here to help.
                    </p>
                  </div>
                </div>
                <div className="demand-post-job align-items-start">
                  <div className="demand-post-img">
                    <img src={checks} alt="Img" className="img-fluid" />
                  </div>
                  <div className="demand-content">
                    <p>
                    In an industry that’s constantly evolving, staying connected is key. TechForShare is committed to being more than just a tool for today; we’re a partner for the future. As our community grows, so too does the potential for innovation and improvement across the repair sector.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="work-set-image">
                <div
                  className="recent-pro-img aos"
                  data-aos="zoom-in"
                  data-aos-duration={1000}
                >
                  <img src={abt_3} alt="Img" className="img-fluid " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / Review */}
      {/* Agency */}


      <section className="section job-register">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="register-job-blk">
                <div className="job-content-blk aos" data-aos="fade-up">
                  <h2>Find Your Best Solution With Tech For Share
                  </h2>
                  <p>
                  Discover the perfect repair solutions for your devices. Connect with trusted repair shops and find the parts and solutions you need quickly and easily.
                  </p>
                  <Link to="/register" className="btn all-btn">
                    Join Now
                  </Link>
                </div>
                <div
                  className="see-all mt-0 aos opportunity"
                  data-aos="zoom-in"
                >
                  <img src={job1} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Aboutus;
