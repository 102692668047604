import axios from "axios";
import TokenManager from "./TokenManager";

const SWAGGER_TECHREQUESTS_URL =
  "https://app-bunduk-techforshareapi-dev.azurewebsites.net/TechRequests";

const TechRequestsApi = {
  getAllTechRequests: async () => {
    try {
      const response = await axios.get(SWAGGER_TECHREQUESTS_URL, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getUserTechRequests: async () => {
    try {
      const response = await axios.get(SWAGGER_TECHREQUESTS_URL+'/usertechrequests', {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getTechRequestByUserID: async (id) => {
    try {
      const response = await axios.get(SWAGGER_TECHREQUESTS_URL+"?userId="+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getTechRequest: async (id) => {
    try {
      const response = await axios.get(SWAGGER_TECHREQUESTS_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addTechRequests: async (payload) => {
    try {
      const response = await axios.post(SWAGGER_TECHREQUESTS_URL+"/",payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  updateTechRequests: async (payload) => {
    try {
      const response = await axios.put(SWAGGER_TECHREQUESTS_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  deleteTechRequests: async (id) => {
    try {
      const response = await axios.delete(SWAGGER_TECHREQUESTS_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addTechRequestsComment: async (payload) => {
    try {
      const response = await axios.post(SWAGGER_TECHREQUESTS_URL+"/addtechrequestcomment",payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  removeTechRequestsComment: async (payload) => {
    try {
      const response = await axios.post(SWAGGER_TECHREQUESTS_URL+"/removetechrequestcomment",payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default TechRequestsApi;
