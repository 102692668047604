/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createContext, useContext,  useState } from 'react';
import SubCategoryApi from '../../Apis/SubCategoryApi';

// Create the context
const SubCategoryContext = createContext();

// Create a provider component
export const SubCategoryProvider = ({ children }) => {
    const [subCategories, setSubCategories] = useState([]);

    // Fetch all categories when the component mounts
    const fetchSubCategories = async (id) => {
        const response = await SubCategoryApi.getAllSubCategoriesByCatId(id)
        if (response.success) {
            setSubCategories(response.data);
            return response.data
        }
    };

    // Function to get a category by its ID
    const getSubCategoryByCatId =async (id) => {
        return await fetchSubCategories(id);
    };

    return (
        <SubCategoryContext.Provider value={{ subCategories, fetchSubCategories }}>
            {children}
        </SubCategoryContext.Provider>
    );
};

// Custom hook to use the CategoryContext
export const useSubCategory = () => {
    return useContext(SubCategoryContext);
};
