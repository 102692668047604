/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from '../../../commoncomponent/sidebar'
import { itemRender, onShowSizeChange } from "../../../paginationfunction"
import "../../../antdstyle.css"
import ViewDetails from '../../../commoncomponent/viewdetails'
import { icon_01, icon_05, icon_06, icon_07, avatar_10, avatar_14, avatar_15, avatar_16, export_icon } from '../../pages/imagepath'
import AuthApi from '../../../../Apis/AuthApi';
import { toast } from 'react-toastify';
const WithDrawn = () => {
    const [users,setUsers]=useState()
    const [selectedUser,setSelectedUser]=useState()
    const [emailFilter,setEmailFilter]=useState("")
    const [kvkFilter,setKvkFilter]=useState("")
    const [statusFilter,setStatusFilter]=useState("")
    const [inputfilter, setInputfilter] = useState(false);
    const togglefilter = (value) => {
        setInputfilter(value)
    }

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            render: (text, record) => (
                <>{text}</>
            ),
            sorter: (a, b) => a.order.length - b.order.length,
        },
        {
            title: "User",
            dataIndex: "user",
            render: (text, record) => (
                <>
                    <div className="table-avatar user-profile">
                        <a href={`/company-details/${record?.id}`}><img className="avatar-img rounded-circle" alt="User Image" src={record.image} /></a>

                        <div>
                            <h5>
                                <a href={`/company-details/${record?.id}`}>{record.businessName}</a> 
                            </h5>
                            {/* <p> {record.email}</p> */}
                        </div>
                    </div>

                </>
            ),
            sorter: (a, b) => a.user.length - b.user.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            render: (text, record) => (

                <>
                    <div className="table-avatar content-user">
                        <div>
                            <h5>{record.email}</h5>
                            {/* <p>{record.date}</p> */}
                        </div>
                    </div>
                </>
            ),
            sorter: (a, b) => a.details.length - b.details.length,
        },
        {
            title: "KVK",
            dataIndex: "kvk",
            render: (text, record) => (
                <>
                    <div className='content-user'>
                        <p>{record.kvk}</p>
                    </div>
                </>
            ),
            sorter: (a, b) => a.tnxby.length - b.tnxby.length,
        },

        {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            render: (text, record) => (
                <>
                    <div className="table-avatar content-user">
                        <div>
                            <h5 className='text-danger'>{text}</h5>
                            {/* <p>{record.balance}</p> */}
                        </div>
                    </div>
                </>
            ),
            sorter: (a, b) => a.amount.length - b.amount.length,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => (
                <>
                    <Link to="#" className={` ${record.status === 'Approved' ? 'user-active-btn' : record.status === 'Pending' ? 'pending' : 'text-danger'}`}>
                        {record.status} 
                    </Link></>
            ),
            sorter: (a, b) => a.status.length - b.status.length,
        },
        {
            title: "",
            dataIndex: "",
            render: (text, record) => (
                <>
                    <div className='three-dots'>
                        <Link to="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                            <i className="fas fa-ellipsis-v" />
                        </Link>
                        <div className="dropdown-menu user-menu-list">
                            <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#transaction-category"
                            onClick={()=>setSelectedUser(text)}
                            >
                                <img className="me-2 " src={icon_01} alt="" />{" "}
                                View Details
                            </Link>
                            {/* <Link className="dropdown-item" to="/admin/user-profile">
                                <img className="me-2 " src={icon_05} alt="" />{" "}
                                User Profile
                            </Link> */}
                            <Link className="dropdown-item" to="#" onClick={()=>approveUser(record.id)}>
                                <img className="me-2 " src={icon_06} alt="" />{" "}
                                Approve
                            </Link>
                            <Link className="dropdown-item mb-0" to="#">
                                <img
                                    className="me-2 "
                                    src={icon_07}
                                    alt=""
                                />{" "}
                                Reject{" "}
                            </Link>
                        </div>
                    </div>
                </>
            ),
        },
];
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
        },
        getCheckboxProps: record => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
            className: "checkbox-red"
        })
    };

    const gelAllUsers=async()=>{
        const response = await AuthApi.getAllUsers(emailFilter,kvkFilter,statusFilter)

        if(response.success){
            setUsers(response.data)
        }else{
            toast.error("could not get users")
        }
    }

    const approveUser=async(id)=>{
        const response = await AuthApi.approveUser(id)
        if(response.success){
            toast.success("User approved successfully")
            window.location.reload()
        }else{
            toast.error("User could not be approved ")
        }
    }

    const rejectUser=async(id)=>{
        const response = await AuthApi.re(id)
        if(response.success){
            toast.success("User approved successfully")
        }else{
            toast.success("User could not be approved ")
        }
    }



    useEffect(()=>{
        gelAllUsers()
    },[])
    console.log(users)
    return (
        <>
            <div className='main-wrapper'>
                <Sidebar />
                <>
                    {/* Page Wrapper */}
                    <div className="page-wrapper">
                        <div className="content report-box container-fluid">
                            {/* Page Header */}
                            <div className="page-header subscribe-head border-bottom-0">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h3 className="page-title">User Managment</h3>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/admin/index">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="subscribe-employe users-list">
                                        <ul>
                                            <li className="active">
                                                <Link to="/admin/withdrawn">All Users </Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/admin/withdrawn-pending">Pending Users</Link>
                                            </li> */}
                                            {/* <li>
                                                <Link to="/admin/withdrawn-cancelled">Cancelled</Link>
                                            </li>
                                            <li>
                                                <Link to="/admin/withdrawn-completed">Completed</Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                    {/* Active User Header */}
                                    <div className="page-header user-active">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h3 className="page-title">Users</h3>
                                                <p>
                                                    Total <span>{users?.length}</span> Users
                                                </p>
                                            </div>
                                            <div className="col-auto">
                                                {/* <Link to="#" className="btn export-btn me-1">
                                                    <img src={export_icon} alt="" /> Export
                                                </Link> */}
                                                <Link
                                                    className="btn filter-btn"
                                                    to="#"
                                                    id="filter_search"
                                                >
                                                    <i className="fas fa-filter" onClick={() => togglefilter(!inputfilter)} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /Active User Header */}
                                    {/* Search Filter */}
                                    <div className="card filter-card" id="filter_inputs" style={{ display: inputfilter ? "block" : "none" }}>
                                        <div className="card-body pb-0">
                                            <form action="#" method="post">
                                                <div className="row filter-row">
                                                    <div className="col-sm-6 col-md-3">
                                                        <div className="form-group">
                                                            <label>KVK</label>
                                                            <input className="form-control" type="text"
                                                            value={kvkFilter}
                                                            onChange={(e)=>setStatusFilter(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-3">
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            <input type="email" className="form-control"
                                                            value={emailFilter}
                                                            onChange={(e)=>setEmailFilter(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-3">
                                                        <div className="form-group">
                                                            <label>Status</label>
                                                            <input type="text" className="form-control" 
                                                            
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-3">
                                                        <div className="form-group">
                                                            <button className="btn btn-primary btn-block" type="button" onClick={gelAllUsers}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* /Search Filter */}
                                    <div className="card ">
                                        <div className="table-responsive">
                                            <Table
                                                rowSelection={rowSelection}
                                                pagination={{
                                                    total: users?.length,
                                                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                    showSizeChanger: true, onShowSizeChange: onShowSizeChange, itemRender: itemRender
                                                }}
                                                className="table"
                                                style={{ overflowX: "auto" }}
                                                columns={columns}
                                                dataSource={users}
                                                rowKey={(record) => record.id} 
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <ul className="paginations list-pagination text-end mb-4">
                                                <li>
                                                    <Link to="#">
                                                        <i className="fas fa-angle-left" /> Previous
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#" className="active">
                                                        1
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">2</Link>
                                                </li>
                                                <li>
                                                    <Link to="#">3</Link>
                                                </li>
                                                <li>
                                                    <Link to="#">4</Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        Next <i className="fas fa-angle-right" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Page Wrapper */}
                    <ViewDetails selectedUser={selectedUser}/>
                </>
            </div>
        </>
    )
}
export default WithDrawn; 