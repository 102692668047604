/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiArrowRight, FiArrowUp } from "react-icons/fi";
import { BiMap } from "react-icons/bi";
// Import Images
import {
  Plat_form,
  Project_19,
  Reg_icon,
  logo_icon_01,
  Project_20,
  logo_icon_02,
  Project_21,
  Project_22,
  logo_icon_03,
  Project_23,
  Project_24,
  logo_icon_04,
  About_01,
  About_02,
  Location_01,
  Location_02,
  Location_03,
  Location_04,
  Location_05,
  Location_06,
  Location_07,
  Location_08,
  Great_icon_01,
  Great_icon_02,
  Great_icon_03,
  Great_icon_04,
} from "../imagepath";
import {default as banner} from '../../../frontend/assets/img/Mokoto2.gif'
import AOS from "aos";
// import "aos/dist/aos.css";
// import HeaderTwo from "../header/headertwo";
// import config from "config"
import FooterTwo from "../footer/index-two";
// import ReviewUser from "./slider/ReviewsUser";
// import FeaturedBlog from "./slider/Featureblogs";
// import TopHiringCompany1 from "./slider/TopHiringcompany1";
import CountUp from "react-countup";
import Select from "react-select";
// import Footer from "../footer/index"

const Home2 = () => {
  const history=useHistory()
  const [scroll, setScroll] = useState(true);
  const [searchWord,setSearchWord]=useState("")

  const options = [
    { value: 1, label: "Mobile" },
    { value: 2, label: "Laptop" },
    { value: 3, label: "Other" },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  useEffect(() => {
    document.body.classList.add("home-page");
    return () => document.body.classList.remove("home-page");
  }, []);
  useEffect(() => {
    document.body.classList.add("bg-two");
    return () => document.body.classList.remove("bg-two");
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150);
    });
    return () =>
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY > 150);
      });
  }, []);
  useEffect(() => {
    setTimeout(function () {
      $("#global-loader");
      setTimeout(function () {
        $("#global-loader").fadeOut("slow");
      }, 100);
    }, 500);
  }, []);

  //const history = useHistory();
  const eventclick = () => {
    localStorage.setItem("searchTerm",searchWord)
    history.push("/posts", { state: { searchWord } });
  };
  return (
    <> 
      {/* Loader */}
      {/* <div id="global-loader">
        <div className="whirly-loader"> </div>
        <div className="loader-img">
          <img src={load_icon} className="img-fluid" alt="" />
        </div>
      </div> */}
      {/* Loader */}
      {/* Main Wrapper */}
      <div className="">
        {/* Start Navigation */}
        {/* Header */}
        {/* <HeaderTwo /> */}
        {/* /Header */}
        <>
          {/* Home Banner */}
          <section className="section home-banner row-middle home-two">
            <div className="container">
              <div className="row align-items-center">
                <div className=" col-lg-6 col-md-12">
                  <div className="banner-content aos" data-aos="fade-up">
                    <div className="market-place">
                      <h3>With the  #1 tech marketplace in the Netherlands</h3>
                    </div>
                    <h1>
                      Find the perfect <br />
                      Parts &amp; Repair shops
                    </h1>
                    <form
                      className="form"
                      name="store"
                      id="store"
                    >
                      <div className="form-inner">
                        <div className="input-group">
                          {/* <span className="drop-detail borderless">
                            <Select
                              className="borderless"
                              options={options}
                              placeholder="Category"
                            />
                          </span> */}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Keywords"
                            value={searchWord} 
                            onChange={(e)=>setSearchWord(e.target.value)}
                          />
                          <button
                            className="btn btn-primary sub-btn"
                            onClick={eventclick}
                          >
                            Search
                          </button>
                        </div> 
                      </div>
                    </form>
                    <div className="home-count">
                      <ul className="nav">
                        <li className="course-count">
                          <h3>
                            +<CountUp end={30} duration={10} />
                          </h3>
                          <span className="list-count">
                            Repair shops
                          </span>
                        </li>
                        <li className="course-count">
                          <h3>
                            +<CountUp end={150} duration={10} />
                          </h3>
                          <span className="list-count">Parts Added</span>
                        </li>
                        <li className="course-count">
                          <h3>
                            +<CountUp end={15} duration={10} />
                          </h3>
                          <span className="list-count">Diffrent categories</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="banner-two-img aos" data-aos="fade-up">
                    <img
                      src={banner}
                      className="img-fluid trusted-user-img aos"
                      data-aos="zoom-in"
                      alt="banner"
                      data-aos-duration={1000}
                    />
                    {/* <img
                      src={hometwobanner02}
                      className="img-fluid best-marketing-img aos"
                      data-aos="zoom-in"
                      alt="banner"
                      data-aos-duration={3000}
                    /> */}
                  </div>
                </div>
              </div>
              {/* <div class="banner-top-bottom">
                  <Link to="#bottom-scroll"><i class="feather-arrow-down"></i></Link>
                </div> */}
            </div>
          </section>
          {/* /Home Banner */}
        </>

        {/* Our Feature */}
        <section className="section update-project">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12 mx-auto">
                <div
                  className="section-header section-header-two text-center aos"
                  data-aos="fade-up"
                >
                  <h2 className="header-title">
                    Recently Added <span>Parts Requests</span>
                  </h2>
                  <p>Get connected with people and share your parts</p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box ">
                    <div className="update-content">
                      <h6>LCD Screens</h6>
                      <p>40 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>LED Screens</h6>
                      <p>60 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Touchscreens</h6>
                      <p>221 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Screen Cables</h6>
                      <p>40 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Standard Batteries</h6>
                      <p>40 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Extended Batteries</h6>
                      <p>40 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Full Keyboards</h6>
                      <p>50 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Keyboard Keys</h6>
                      <p>30 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Motherboards</h6>
                      <p>60 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Speakers</h6>
                      <p>80 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Rear Cameras</h6>
                      <p>70 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              {/* Updated Item */}
              <div className="col-xl-3 col-md-6">
                <div className="aos" data-aos="fade-up">
                  <Link to="posts" className="update-project-blk move-box">
                    <div className="update-content">
                      <h6>Front Cameras</h6>
                      <p>93 Parts Available</p>
                    </div>
                    <div className="update-icon-end">
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Link>
                </div>
              </div>
              {/* /Updated Item */}
              <div className="col-xl-12">
                <div
                  className="more-project text-center aos"
                  data-aos="fade-up"
                >
                  <Link to="posts" className="btn btn-primary">
                    View More Posts
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Our Feature */}

        {/* Our Platform */}
        <section className="section platform">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="plat-form-img aos" data-aos="fade-up">
                  <img src={Plat_form} alt="Img" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="platform-group aos" data-aos="fade-up">
                  <h1>
                    Discover Repair Shops Around your Location{" "}
                    <span> In Our Platform</span>
                  </h1>
                  <h5>Find Local Repair Shops</h5>
                  <p>
                  Easily locate and connect with repair shops in your area through our platform. Whether you need laptop, computer, or mobile phone repairs, find the right parts and services nearby.
                  </p>
                  <div className="market-place-btn platform-btn">
                    <Link
                      to="/posts"
                      className="btn btn-primary market-project me-2"
                    >
                      View Posts
                    </Link>
                    <Link
                      to="/request-part"
                      className="btn btn-primary project-post"
                    > 
                      Request a part
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* /Our Platform */}
        {/* Feature */}
        <section className="section feature-project home-two-projects">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12 mx-auto">
                <div
                  className="section-header section-header-two text-center aos"
                  data-aos="fade-up"
                >
                  <h2 className="header-title">
                    Featured <span>Posts</span> for you
                  </h2>
                  <p>We have over 200+ Posts waiting for you</p>
                </div>
              </div>
            </div>
            <div className="row">
              {/*- Project Item  */}
              <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
                <div
                  className="project-item feature-project-item aos"
                  data-aos="fade-up"
                >
                  <div className="project-img">
                    <Link to="posts">
                      <img src={Project_19} alt="" className="img-fluid" />
                    </Link>
                  </div>
                  <div className="feature-content">
                    <div className="feature-time-blk">
                      <Link to="#" className="btn btn-primary green-active">
                        Mobile
                      </Link>
                      <span>
                        <i className="far fa-clock me-1" /> 1 min ago
                      </span>
                    </div>
                    <h4>
                      <Link to="posts">Samsung S22 Rear Camera</Link>
                    </h4>
                    <ul className="feature-project-list nav">
                      <li>
                      <BiMap className="feather-map-pin me-1" />
                      Eindhoven, Netherlands
                      </li>
                      {/* <li>
                        <BiMap className="feather-map-pin me-1" />
                        Eindhoven
                      </li> */}
                    </ul>
                    <div className="feature-foot">
                      <div className="logo-company">
                        <Link to="posts">
                          <img src={logo_icon_01} width={50} className="me-1" alt="icon" />
                          <span>Tech Line</span>
                        </Link>
                      </div>
                      <Link to="project-details" className="bid-now">
                        Bid Now{" "}
                        <FiArrowRight className="feather-arrow-right ms-1" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*- /Project Item  */}
              {/*- Project Item  */}
              <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
                <div
                  className="project-item feature-project-item aos"
                  data-aos="fade-up"
                >
                  <div className="project-img">
                    <Link to="posts">
                      <img src={Project_20} alt="" className="img-fluid" />
                    </Link>
                  </div>
                  <div className="feature-content">
                    <div className="feature-time-blk">
                      <Link to="#" className="btn btn-primary sky-active">
                        Laptop
                      </Link>
                      <span>
                        <i className="far fa-clock me-1" /> 10 min ago
                      </span>
                    </div>
                    <h4>
                      <Link to="posts">
                      MacBook Pro 14-inch Keyboard
                      </Link>
                    </h4>
                    <ul className="feature-project-list nav">
                    <li>
                        <BiMap className="feather-map-pin me-1" />
                        Amsterdam, Netherlands
                      </li>
                    </ul>
                    <div className="feature-foot">
                      <div className="logo-company">
                      <Link to="posts">
                          <img src={logo_icon_01} width={50} className="me-1" alt="icon" />
                          <span>Tech Line</span>
                        </Link>
                      </div>
                      <Link to="project-details" className="bid-now">
                        Bid Now{" "}
                        <FiArrowRight className="feather-arrow-right ms-1" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*- /Project Item  */}
              {/*- Project Item  */}
              <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
                <div
                  className="project-item feature-project-item aos"
                  data-aos="fade-up"
                >
                  <div className="project-img">
                    <Link to="posts">
                      <img src={Project_21} alt="" className="img-fluid"/>
                    </Link>
                  </div>
                  <div className="feature-content">
                    <div className="feature-time-blk">
                      <Link to="#" className="btn btn-primary green-active">
                        Mobile
                      </Link>
                      <span>
                        <i className="far fa-clock me-1" /> 5 min ago
                      </span>
                    </div>
                    <h4>
                      <Link to="posts">
                      OnePlus 9 Back Cover
                      </Link>
                    </h4>
                    <ul className="feature-project-list nav">
                    <li>
                        <BiMap className="feather-map-pin me-1" />
                        Utrecht, Netherlands
                      </li>
                    </ul>
                    <div className="feature-foot">
                      <div className="logo-company">
                      <Link to="posts">
                          <img src={logo_icon_01} width={50} className="me-1" alt="icon" />
                          <span>Tech Line</span>
                        </Link>
                      </div>
                      <Link to="project-details" className="bid-now">
                        Bid Now{" "}
                        <FiArrowRight className="feather-arrow-right ms-1" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*- /Project Item  */}
              {/*- Project Item  */}
              <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
                <div
                  className="project-item feature-project-item aos"
                  data-aos="fade-up"
                >
                  <div className="project-img">
                    <Link to="posts">
                      <img src={Project_22} alt="" className="img-fluid" />
                    </Link>
                  </div>
                  <div className="feature-content">
                    <div className="feature-time-blk">
                      <Link to="#" className="btn btn-primary green-active">
                        Mobile
                      </Link>
                      <span>
                        <i className="far fa-clock me-1" /> 6 min ago
                      </span>
                    </div>
                    <h4>
                      <Link to="posts">
                       Xiaomi Mi 11 SIM Tray
                      </Link>
                    </h4>
                    <ul className="feature-project-list nav">
                    <li>
                        <BiMap className="feather-map-pin me-1" />
                        Tilburg, Netherlands
                      </li>
                    </ul>
                    <div className="feature-foot">
                      <div className="logo-company">
                      <Link to="posts">
                          <img src={logo_icon_01} width={50} className="me-1" alt="icon" />
                          <span>Tech Line</span>
                        </Link>
                      </div>
                      <Link to="project-details" className="bid-now">
                        Bid Now{" "}
                        <FiArrowRight className="feather-arrow-right ms-1" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*- /Project Item  */}
              {/*- Project Item  */}
              <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
                <div
                  className="project-item feature-project-item aos"
                  data-aos="fade-up"
                >
                  <div className="project-img">
                    <Link to="posts">
                      <img src={Project_23} alt="" className="img-fluid" />
                    </Link>
                  </div>
                  <div className="feature-content">
                    <div className="feature-time-blk">
                      <Link to="#" className="btn btn-primary sky-active">
                        Laptop
                      </Link>
                      <span>
                        <i className="far fa-clock me-1" /> 2 min ago
                      </span>
                    </div>
                    <h4>
                      <Link to="posts">
                      Lenovo ThinkPad X1 Carbon Motherboard
                      </Link>{" "}
                    </h4>
                    <ul className="feature-project-list nav">
                    <li>
                        <BiMap className="feather-map-pin me-1" />
                        Eindhoven, Netherlands
                      </li>

                    </ul>
                    <div className="feature-foot">
                      <div className="logo-company">
                      <Link to="posts">
                          <img src={logo_icon_01} width={50} className="me-1" alt="icon" />
                          <span>Tech Line</span>
                        </Link>
                      </div>
                      <Link to="project-details" className="bid-now">
                        Bid Now{" "}
                        <FiArrowRight className="feather-arrow-right ms-1" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*- /Project Item  */}
              {/*- Project Item  */}
              <div className="col-lg-4 col-md-6 col-sm-6 d-flex">
                <div
                  className="project-item feature-project-item aos"
                  data-aos="fade-up"
                >
                  <div className="project-img">
                    <Link to="posts">
                      <img src={Project_24} alt="" className="img-fluid" />
                    </Link>
                  </div>
                  <div className="feature-content">
                    <div className="feature-time-blk">
                      <Link to="#" className="btn btn-primary green-active">
                        Mobile
                      </Link>
                      <span>
                        <i className="far fa-clock me-1" /> 1 min ago
                      </span>
                    </div>
                    <h4>
                      <Link to="posts">iPhone 13 Power Button</Link>{" "}
                    </h4>
                    <ul className="feature-project-list nav">
                    <li>
                        <BiMap className="feather-map-pin me-1" />
                        Roterdam, Netherlands
                      </li>
                    </ul>
                    <div className="feature-foot">
                      <div className="logo-company">
                      <Link to="posts">
                          <img src={logo_icon_01} width={50} className="me-1" alt="icon" />
                          <span>Tech Line</span>
                        </Link>
                      </div>
                      <Link to="project-details" className="bid-now">
                        Bid Now{" "}
                        <FiArrowRight className="feather-arrow-right ms-1" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*- /Project Item  */}
              <div className="col-xl-12">
                <div
                  className="more-project text-center aos"
                  data-aos="fade-up"
                >
                  <Link to="posts" className="btn btn-primary">
                    View More Posts
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*- /Feature  */}

        {/* Feature Developer */}
        {/* <section className="section feature-developer home-two-developers">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12 mx-auto">
                <div
                  className="section-header section-header-two text-center aos"
                  data-aos="fade-up"
                >
                  <h2 className="header-title">
                    Featured <span>Developers</span>
                  </h2>
                  <p>We have over 1400+ Developers </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-6">
                <div className="feature-develop-blk aos" data-aos="fade-up">
                  <div className="developer-sub-blk">
                    <div className="img-developer">
                      <Link to="/developer-details">
                        <img src={Avatar_21} className="me-2" alt="Img" />
                      </Link>
                    </div>
                    <div className="developer-content">
                      <h4>
                        <Link to="/developer-details">Dran Gonzalez</Link>
                      </h4>
                      <p>React Developer</p>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span className="average-rating">5.0 (30)</span>
                      </div>
                    </div>
                  </div>
                  <div className="hour-rate">
                    <ul className="nav">
                      <li>
                        <i className="feather-dollar-sign me-1" />
                        $34 Hourly
                      </li>
                      <li>
                        <i className="feather-map-pin me-1" />
                        Florida, USA
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="feature-develop-blk aos" data-aos="fade-up">
                  <div className="developer-sub-blk">
                    <div className="img-developer">
                      <Link to="/developer-details">
                        <img src={Avatar_22} className="me-2" alt="Img" />
                      </Link>
                    </div>
                    <div className="developer-content">
                      <h4>
                        <Link to="/developer-details">Nicole Black</Link>
                      </h4>
                      <p>Angular Developer</p>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span className="average-rating">5.0 (30)</span>
                      </div>
                    </div>
                  </div>
                  <div className="hour-rate">
                    <ul className="nav">
                      <li>
                        <i className="feather-dollar-sign me-1" />
                        $32 Hourly
                      </li>
                      <li>
                        <i className="feather-map-pin me-1" />
                        Nevada, USA
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="feature-develop-blk aos" data-aos="fade-up">
                  <div className="developer-sub-blk">
                    <div className="img-developer">
                      <Link to="/developer-details">
                        <img src={Avatar_23} className="me-2" alt="Img" />
                      </Link>
                    </div>
                    <div className="developer-content">
                      <h4>
                        <Link to="/developer-details">Megan Torres</Link>
                      </h4>
                      <p>Java Developer</p>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span className="average-rating">5.0 (30)</span>
                      </div>
                    </div>
                  </div>
                  <div className="hour-rate">
                    <ul className="nav">
                      <li>
                        <i className="feather-dollar-sign me-1" />
                        $24 Hourly
                      </li>
                      <li>
                        <i className="feather-map-pin me-1" />
                        Delaware, USA
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="feature-develop-blk aos" data-aos="fade-up">
                  <div className="developer-sub-blk">
                    <div className="img-developer">
                      <Link to="/developer-details">
                        <img src={Avatar_24} className="me-2" alt="Img" />
                      </Link>
                    </div>
                    <div className="developer-content">
                      <h4>
                        <Link to="/developer-details">Shan Morris</Link>
                      </h4>
                      <p>React Developer</p>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span className="average-rating">5.0 (30)</span>
                      </div>
                    </div>
                  </div>
                  <div className="hour-rate">
                    <ul className="nav">
                      <li>
                        <i className="feather-dollar-sign me-1" />
                        $28 Hourly
                      </li>
                      <li>
                        <i className="feather-map-pin me-1" />
                        Florida, USA
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="feature-develop-blk aos" data-aos="fade-up">
                  <div className="developer-sub-blk">
                    <div className="img-developer">
                      <Link to="/developer-details">
                        <img src={Avatar_25} className="me-2" alt="Img" />
                      </Link>
                    </div>
                    <div className="developer-content">
                      <h4>
                        <Link to="/developer-details">Dran Gonzalez</Link>
                      </h4>
                      <p>React Developer</p>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span className="average-rating">5.0 (30)</span>
                      </div>
                    </div>
                  </div>
                  <div className="hour-rate">
                    <ul className="nav">
                      <li>
                        <i className="feather-dollar-sign me-1" />
                        $34 Hourly
                      </li>
                      <li>
                        <i className="feather-map-pin me-1" />
                        Delaware, USA
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="feature-develop-blk aos" data-aos="fade-up">
                  <div className="developer-sub-blk">
                    <div className="img-developer">
                      <Link to="/developer-details">
                        <img src={Avatar_26} className="me-2" alt="Img" />
                      </Link>
                    </div>
                    <div className="developer-content">
                      <h4>
                        <Link to="/developer-details">Nicole Black</Link>
                      </h4>
                      <p>Angular Developer</p>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span className="average-rating">5.0 (30)</span>
                      </div>
                    </div>
                  </div>
                  <div className="hour-rate">
                    <ul className="nav">
                      <li>
                        <i className="feather-dollar-sign me-1" />
                        $312 Hourly
                      </li>
                      <li>
                        <i className="feather-map-pin me-1" />
                        Nevada, USA
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="feature-develop-blk aos" data-aos="fade-up">
                  <div className="developer-sub-blk">
                    <div className="img-developer">
                      <Link to="/developer-details">
                        <img src={Avatar_27} className="me-2" alt="Img" />
                      </Link>
                    </div>
                    <div className="developer-content">
                      <h4>
                        <Link to="/developer-details">Megan Torres</Link>{" "}
                      </h4>
                      <p>Java Developer</p>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span className="average-rating">5.0 (30)</span>
                      </div>
                    </div>
                  </div>
                  <div className="hour-rate">
                    <ul className="nav">
                      <li>
                        <i className="feather-dollar-sign me-1" />
                        $234 Hourly
                      </li>
                      <li>
                        <i className="feather-map-pin me-1" />
                        Delaware, USA
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="feature-develop-blk aos" data-aos="fade-up">
                  <div className="developer-sub-blk">
                    <div className="img-developer">
                      <Link to="/developer-details">
                        <img src={Avatar_28} className="me-2" alt="Img" />
                      </Link>
                    </div>
                    <div className="developer-content">
                      <h4>
                        <Link to="/developer-details">Shan Morris</Link>
                      </h4>
                      <p>React Developer</p>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span className="average-rating">5.0 (30)</span>
                      </div>
                    </div>
                  </div>
                  <div className="hour-rate">
                    <ul className="nav">
                      <li>
                        <i className="feather-dollar-sign me-1" />
                        $28 Hourly
                      </li>
                      <li>
                        <i className="feather-map-pin me-1" />
                        Florida, USA
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div
                  className="more-project text-center aos"
                  data-aos="fade-up"
                >
                  <Link to="/developer-details" className="btn btn-primary">
                    View More Freelancers
                  </Link>
                </div>
                <div
                  className="review-bottom text-center aos"
                  data-aos="fade-up"
                >
                  <div className="client-rate">
                    <h4>Clients rate our Data Processing Executives</h4>
                    <div className="rating">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <span className="average-rating">5.0 (30)</span>
                    </div>
                    <p>from 4227 reviews</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* /Feature Developer */}

        {/* Top skill */}
        {/* <section className="section top-skill">
          <div className="container">
            <div
              className="section-header section-header-two text-center aos"
              data-aos="fade-up"
            >
              <h2 className="header-title">
                Featured <span> Skills</span>
              </h2>
              <p>Get work done in over 1800 different categories </p>
            </div>
            <div className="row justify-content-center aos" data-aos="fade-up">
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon01} alt="Img" />
                  </div>
                  <span>Translation</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon02} alt="Img" />
                  </div>
                  <span>Research Writing</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon03} alt="Img" />
                  </div>
                  <span>Web Scraping</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon04} alt="Img" />
                  </div>
                  <span>Article Writing</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon05} alt="Img" />
                  </div>
                  <span>HTML 5</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon06} alt="Img" />
                  </div>
                  <span>Website Design</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon07} alt="Img" />
                  </div>
                  <span>Mobile Apps</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon08} alt="Img" />
                  </div>
                  <span>Android Apps</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon09} alt="Img" />
                  </div>
                  <span>Iphone Apps</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon10} alt="Img" />
                  </div>
                  <span>Software Architecture</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon11} alt="Img" />
                  </div>
                  <span>Graphic Design</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon12} alt="Img" />
                  </div>
                  <span>Logo Design</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon13} alt="Img" />
                  </div>
                  <span>Public Relations</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon14} alt="Img" />
                  </div>
                  <span>Proofreading</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon15} alt="Img" />
                  </div>
                  <span>Photoshop</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon16} alt="Img" />
                  </div>
                  <span>Technical Writing</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon17} alt="Img" />
                  </div>
                  <span>Blogging</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon18} alt="Img" />
                  </div>
                  <span>Internet Marketing</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon19} alt="Img" />
                  </div>
                  <span>eCommerce</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon20} alt="Img" />
                  </div>
                  <span>Data Entry</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon21} alt="Img" />
                  </div>
                  <span>Link Building</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon22} alt="Img" />
                  </div>
                  <span>C++ Programming</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon24} alt="Img" />
                  </div>
                  <span>C# Programming</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon13} alt="Img" />
                  </div>
                  <span>Content Writing</span>
                </div>
              </div>
              <div className="skill-custom-col">
                <div className="skills-feature">
                  <div className="skill-icon">
                    <img src={skillicon25} alt="Img" />
                  </div>
                  <span>Marketing</span>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* /Top skill */}

        {/* Great About */}
        <section className="section great-about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12 mx-auto">
                <div
                  className="section-header section-header-two text-center aos"
                  data-aos="fade-up"
                >
                  <h2 className="header-title">
                    What's Great About <span>Tech For Share</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-6">
                <div className="great-blk aos" data-aos="fade-up">
                  <div className="great-icon">
                    <img src={Great_icon_01} alt="Img" width={50}/>
                  </div>
                  <div className="great-content">
                    <h4>Browse repair shops</h4>
                    <p>
                    Find trusted professionals by exploring their profiles, viewing their past work, and reading reviews from other users.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="great-blk aos" data-aos="fade-up">
                  <div className="great-icon">
                    <img src={Great_icon_02} alt="Img" width={50}/>
                  </div>
                  <div className="great-content">
                    <h4>Fast bids</h4>
                    <p>
                    Get quick responses from repair shops for your part requests. 80% of requests receive quotes within 60 seconds.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="great-blk aos" data-aos="fade-up" style={{height:"270px"}}>  
                  <div className="great-icon">
                    <img src={Great_icon_03} alt="Img" width={50}/>
                  </div>
                  <div className="great-content">
                    <h4>Request parts</h4>
                    <p>
                    Easily request specific parts and get quotes from multiple shops quickly to find the best deal.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="great-blk aos" data-aos="fade-up">
                  <div className="great-icon">
                    <img src={Great_icon_04} alt="Img" width={50}/>
                  </div>
                  <div className="great-content">
                    <h4>Browse for parts</h4>
                    <p>
                    Discover a wide range of parts available from various repair shops and compare prices to find exactly what you need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Great About */}

        {/* About Project */}
        <section className="section about-project ">
          <div className="about-position">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 d-flex">
                  <div className="about-it-blk w-100 aos" data-aos="fade-up">
                    <div className="about-it-img">
                      <Link to="javascript:void(0);">
                        <img className="img-fluid" src={About_01} alt="Img" />
                      </Link>
                    </div>
                    <div className="about-it-content text-center">
                      <h4>I need a specific Part</h4>
                      <p>
                        Quickly find and request the exact part you need from our extensive network of repair shops.
                      </p>
                      <div className="more-project text-center mt-0">
                        <Link
                          to="/request-part"
                          className="btn btn-primary project-post"
                        >
                          Request Part
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="about-it-blk w-100 aos" data-aos="fade-up">
                    <div className="about-it-img">
                      <Link to="javascript:void(0);">
                        <img className="img-fluid" src={About_02} alt="Img" />
                      </Link>
                    </div>
                    <div className="about-it-content text-center">
                      <h4>Browse repair shop's posts</h4>
                      <p>
                      Explore posts from various repair shops showcasing their expertise, services, and available parts.
                      </p>
                      <div className="more-project text-center mt-0">
                        <Link
                          to="/post-project"
                          className="btn btn-primary start-bid"
                        >
                          Start Bidding
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /About Project */}

        {/* Job Location */}
        <section className="section job-location home-two-jobsec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12 mx-auto">
                <div
                  className="section-header section-header-two d-block text-center section-locate aos"
                  data-aos="fade-up"
                >
                  <h2 className="header-title">
                    Repair shops By <span>Location</span>
                  </h2>
                  <p>
                  Discover Repair Shops Near You
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <div className="job-locate-blk aos" data-aos="fade-up">
                  <div className="location-img">
                    <Link to="/posts">
                      <img className="img-fluid" src={Location_01} alt="Img" />
                    </Link>
                  </div>
                  <div className="job-it-content">
                    <h6>Eindhoven, Netherland</h6>
                    <ul className="nav job-locate-foot">
                      <li>20 Shop</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4">
                <div className="job-locate-blk aos" data-aos="fade-up">
                  <div className="location-img">
                    <Link to="/posts">
                      <img className="img-fluid" src={Location_02} alt="Img" />
                    </Link>
                  </div>
                  <div className="job-it-content">
                    <h6>Amsterdam, Netherland</h6>
                    <ul className="nav job-locate-foot">
                    <li>40 Shop</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4">
                <div className="job-locate-blk aos" data-aos="fade-up">
                  <div className="location-img">
                    <Link to="/posts">
                      <img className="img-fluid" src={Location_03} alt="Img" />
                    </Link>
                  </div>
                  <div className="job-it-content">
                    <h6>Utrecht, Netherland</h6>
                    <ul className="nav job-locate-foot">
                      <li>50 Shop</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4">
                <div className="job-locate-blk aos" data-aos="fade-up">
                  <div className="location-img">
                    <Link to="/posts">
                      <img className="img-fluid" src={Location_04} alt="Img" />
                    </Link>
                  </div>
                  <div className="job-it-content">
                    <h6>Tilburg, Netherland</h6>
                    <ul className="nav job-locate-foot">
                      <li>60 Shop</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4">
                <div className="job-locate-blk aos" data-aos="fade-up">
                  <div className="location-img">
                    <Link to="/posts">
                      <img className="img-fluid" src={Location_05} alt="Img" />
                    </Link>
                  </div>
                  <div className="job-it-content">
                    <h6>Antwerpen, Belguim</h6>
                    <ul className="nav job-locate-foot">
                      <li>80 Shop</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4">
                <div className="job-locate-blk aos" data-aos="fade-up">
                  <div className="location-img">
                    <Link to="/posts">
                      <img className="img-fluid" src={Location_06} alt="Img" />
                    </Link>
                  </div>
                  <div className="job-it-content">
                    <h6>Berlin, Germany</h6>
                    <ul className="nav job-locate-foot">
                      <li>50 Shop</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4">
                <div className="job-locate-blk aos" data-aos="fade-up">
                  <div className="location-img">
                    <Link to="/posts">
                      <img className="img-fluid" src={Location_07} alt="Img" />
                    </Link>
                  </div>
                  <div className="job-it-content">
                    <h6>Roterdam, Netherland</h6>
                    <ul className="nav job-locate-foot">
                      <li>30 Shop</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4">
                <div className="job-locate-blk aos" data-aos="fade-up">
                  <div className="location-img">
                    <Link to="/posts">
                      <img className="img-fluid" src={Location_08} alt="Img" />
                    </Link>
                  </div>
                  <div className="job-it-content">
                    <h6>Best, Netherland</h6>
                    <ul className="nav job-locate-foot">
                      <li>70 Shop</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div
                  className="more-project text-center aos aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <Link to="/posts" className="btn btn-primary">
                    View More Locations
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* /Job Location */}
        {/* Review */}
        {/* <ReviewUser /> */}
        {/* / Review */}
        {/* Company Hire */}
        {/* <TopHiringCompany1 /> */}
        {/* / Company Hire */}
        {/* News */}
        {/* <FeaturedBlog /> */}
        {/* / News */}
        {/* News */}

        {/*Main Wrapper */}
        <FooterTwo />
        {/* Scroll Top */}
        <button
          className={
            scroll
              ? "scroll-top scroll-top-next scroll-to-target open"
              : "scroll-top-next scroll-to-target"
          }
          data-target="html"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <span className="ti-angle-up">
            <FiArrowUp className="feather-arrow-up" />
          </span>
        </button>
        {/* Scroll Top */}
      </div>
    </>
  );
};
export default Home2;
