/* eslint-disable react/prop-types */
// NotificationContext.js
import React, { createContext, useState, useContext } from "react";

// Create the context
const ConversationHub = createContext();

// Custom hook to use the NotificationContext
export const useConversation = () => {
  return useContext(ConversationHub);
};

// Provider component
export const ConversationProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  // Add new notification to the state 
  const addMessage = (messageData) => {
    setMessages(messageData);
  };

  return (
    <ConversationHub.Provider
      value={{
        messages,
        addMessage,
      }}
    >
      {children}
    </ConversationHub.Provider>
  );
};
