/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from 'react';
import CategoriesApi from '../../Apis/CategoriesApi';

// Create the context
const CategoryContext = createContext();

// Create a provider component
export const CategoryProvider = ({ children }) => {
    const [categories, setCategories] = useState([]);

    // Fetch all categories when the component mounts
    const fetchCategories = async () => {
        const response = await CategoriesApi.getAllCategories();
        if (response.success) {
            setCategories(response.data);
        }
    };
    useEffect(() => {
        fetchCategories();
    }, []);

    // Function to get a category by its ID
    const getCategoryById = (id) => {
        const category = categories?.find((cat) => id === cat.id);
        return category ? category.name : undefined;
    };

    return (
        <CategoryContext.Provider value={{ categories, getCategoryById,fetchCategories }}>
            {children}
        </CategoryContext.Provider> 
    );
};

// Custom hook to use the CategoryContext
export const useCategory = () => {
    return useContext(CategoryContext);
};
