import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonRequest = () => {
  return (
    <div className="project-list-card">
      <div className="company-detail-image">
        <Skeleton circle={true} height={50} width={50} />
      </div>
      <div>
        <div className="company-title">
          <p><Skeleton width={100} /></p>
          <h4><Skeleton width={200} /></h4>
        </div>
        <div className="company-splits">
          <div>
            <div className="company-address">
              <ul>
                <li>
                  <i className="feather-map-pin" />
                  <Skeleton width={150} />
                </li>
                <li>
                  <i className="feather-calendar" />
                  <Skeleton width={120} />
                </li>
                <li>
                  <i className="feather-file-2" />
                  <Skeleton width={80} />
                </li>
              </ul>
            </div>
            <div
              className="company-description"
              style={{
                maxWidth: "400px",
                wordWrap: "break-word",
                wordBreak: "break-word",
              }}
            >
              <Skeleton count={3} />
            </div>
            <div className="company-description">
              <div className="tags">
                <Skeleton width={100} height={30} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="company-split-price">
        <Skeleton width={100} height={30} />
      </div>
    </div>
  )
}

export default SkeletonRequest
