/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link,useHistory } from "react-router-dom";
import PasswordApi from '../../../Apis/PasswordApi';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const ForgotPassword = (props) => {
  const [loading,setLoading]=useState(false)
  const history= useHistory()
  const [email,setEmail]=useState()


  const handleResetPassword=async(e)=>{
    e.preventDefault()
    setLoading(true)
    if(email && email !==""){
      const payload={
        email:email
      }
      const response = await PasswordApi.forgetPassword(payload)
      if(response.success){
        toast.success("check your email and click on the reset link")
        history.push("/")
      }else{
        toast.error("could not rest password")
      }
    }else{
      toast.error("Please fill in all fields")
    }
    setLoading(false)

  }

  useEffect(() => { 
    document.body.className = 'account-page';
    return () => { document.body.className = ''; }
  });
  return (
    <>
      {/* Page Content */}
      <div className="login-wrapper">
        <div className="content">
          <div className="container">
            {/* Login Content */}
            <div className="account-content">
              <div className="align-items-center justify-content-center">
                <div className="login-right">
                  <div className="login-header text-center">
                    <h2>Forgot Password</h2>
                    <h3>Please enter your email address</h3>
                  </div>
                  <form action="/dashboard">
                    <div className="input-block">
                      <label className="focus-label">Email Address <span className="label-star"> *</span></label>
                      <input type="text" className="form-control floating" 
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      />
                    </div>
                    <button onClick={handleResetPassword} disabled={loading} className="btn btn-primary w-100 btn-lg login-btn d-flex align-items-center justify-content-center mb-4" type="submit">
                    {loading ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                  <>Send Now<i className="feather-arrow-right ms-2" /></>
                                )}
                      
                      </button>
                    <div className="row">
                      <div className="col-sm-8 dont-have d-flex mt-0  align-items-center">Remember Password<Link to="/login" className="ms-2">Sign in?</Link></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>

      {/* /Page Content */}

    </>
  )

}
export default ForgotPassword;