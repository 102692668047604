/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../commoncomponent/sidebar";
import { Table } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";
import CategoriesApi from "../../../../Apis/CategoriesApi";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Categories = () => {
  const [loading, setLoading] = useState(false);
  const [catergoryToAdd, setCategoryToAdd] = useState("");
  const [catergoryToDelete, setCategoryToDelete] = useState("");
  const [catergoryToUpdate, setCategoryToUpdate] = useState("");
  const [catergoryUpdate, setCategoryUpdate] = useState("");
  const [date, setDate] = useState(new Date());
  const handleChange = (date) => {
    setDate(date);
  };
  const [inputfilter, setInputfilter] = useState(false);

  const togglefilter = (value) => {
    setInputfilter(value);
  };

  const [categories, setCategories] = useState([]);

  const GetAllCategories = async () => {
    setLoading(true);
    const response = await CategoriesApi.getAllCategories();
    if (response.success) {
      setCategories(response.data);
    } else {
      toast.error("could not get categories");
    }
    setLoading(false);
  };

  const deleteCategory = async () => {
    if (catergoryToDelete === "") {
      toast.error("please select category");
      return;
    }
    setLoading(true);
    const response = await CategoriesApi.deleteCategory(catergoryToDelete);
    if (response.success) {
      window.location.reload();
      toast.success("category deleted successfully");
    }
    setLoading(false);
  };

  const addCategory = async () => {
    if (catergoryToAdd === "") {
      toast.error("please add category");
      return;
    }
    const payload = {
      name: catergoryToAdd,
      description: catergoryToAdd,
    };
    setLoading(true);
    const response = await CategoriesApi.addCategory(payload);
    if (response.success) {
      window.location.reload();
      toast.success("category added successfully");
    } else {
      toast.error("could not add category");
    }
    setLoading(false);
  };

  const updateCategory = async () => {
    const payload = {
      categoryId: catergoryToUpdate,
      name: catergoryUpdate,
      describtion: catergoryUpdate,
    };
    setLoading(true);
    const response = await CategoriesApi.updateCategory(payload);
    console.log(response);
    if (response.success) {
      window.location.reload();
      toast.success("category updated successfully");
    } else {
      toast.error("could not update category");
    }
    setLoading(false);
  };

  useEffect(() => {
    GetAllCategories();
  }, []);

  useEffect(() => {
    if (catergoryToUpdate) {
      setCategoryUpdate(
        categories.filter((Cat) => Cat.id === catergoryToUpdate)[0]?.name
      );
    }
  }, [catergoryToUpdate]);

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      render: (text, record) => <>{text}</>,
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Category Name",
      dataIndex: "name",
      render: (text, record) => <>{text}</>,
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Actions",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <div className="text-end">
            <Link
              to="#"
              onClick={() => setCategoryToUpdate(text)}
              className="btn btn-sm btn-secondary me-2"
              data-bs-toggle="modal"
              data-bs-target="#edit-category"
            >
              <i className="far fa-edit" />
            </Link>
            <Link
              to="#"
              onClick={() => setCategoryToDelete(text)}
              className="btn btn-sm btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#delete_category"
            >
              <i className="far fa-trash-alt" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  
  return (
    <>
      <>
        <div className="main-wrapper">
          {/* Page Wrapper */}

          <Sidebar />
          <div className="page-wrapper">
            <div className="content container-fluid">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">Categories</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admin/index">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Categories</li>
                    </ul>
                  </div>
                  <div className="col-auto">
                    <Link
                      to="#"
                      className="btn add-button me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#add-category"
                    >
                      <i className="fas fa-plus" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              {/* Search Filter */}
              <div
                className="card filter-card"
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <form>
                    <div className="row filter-row">
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>Add Categories</label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>From Date</label>
                          <div className="cal-icon">
                            <DatePicker
                              selected={date}
                              onChange={handleChange}
                              className="form-control datetimepicker"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>To Date</label>
                          <div className="cal-icon">
                            <DatePicker
                              selected={date}
                              onChange={handleChange}
                              className="form-control datetimepicker"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* /Search Filter */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table
                          pagination={{
                            total: categories.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          className="table role"
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          dataSource={categories}
                          rowKey={(record) => record.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Wrapper */}
          </div>
          <>
            {/* The Modal */}
            <div className="modal fade custom-modal" id="add-category">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  {/* Modal Header */}
                  <div className="modal-header">
                    <h4 className="modal-title">Add Category</h4>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  {/* Modal body */}
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>Category Name</label>
                        <input
                          type="text"
                          value={catergoryToAdd}
                          onChange={(e) => setCategoryToAdd(e.target.value)}
                          className="form-control"
                          placeholder="Enter Category Name"
                        />
                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          onClick={addCategory}
                          className="btn btn-primary btn-block"
                        >
                          {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /The Modal */}
            {/* Edit Modal */}
            <div className="modal fade custom-modal" id="edit-category">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  {/* Modal Header */}
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Category</h4>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  {/* Modal body */}
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>Category Name</label>
                        <input
                          type="text"
                          value={catergoryUpdate}
                          onChange={(e) => setCategoryUpdate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          onClick={updateCategory}
                          className="btn btn-primary btn-block"
                        >
                          {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Edit Modal */}
            {/*Deelte modal*/}
            <div
              className="modal custom-modal fade"
              id="delete_category"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete</h3>
                      <p>
                        Are you sure want to delete{" "}
                        {
                          categories.filter(
                            (Cat) => Cat.id === catergoryToDelete
                          )[0]?.name
                        }
                        ?
                      </p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <Link
                            to="#"
                            onClick={deleteCategory}
                            className="btn btn-primary continue-btn"
                          >
                            {loading ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              "Delete"
                            )}
                          </Link>
                        </div>
                        <div className="col-6">
                          <Link
                            to="#"
                            data-bs-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </>
    </>
  );
};
export default Categories;
