/* eslint-disable no-unused-vars */
import axios from "axios";
import TokenManager from "./TokenManager";

const Notifications_BASE_URL = "https://app-bunduk-techforshareapi-dev.azurewebsites.net/Notifications"

const NotificationApi = {
    getAllNotifications: async () => {
      try {
        const response = await axios.get(Notifications_BASE_URL, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          },
        });
        return { success: true, response: response.data };
      } catch (error) {
        console.error("error", error);
        return { success: false, error: error?.response?.data?.detail };
      }
    },
    updateNotification: async (ids) => {
      try {
        const claims = TokenManager.getClaims();
        const payload = {
          ids: ids,
          userId: claims.sub,
        };
        const response = await axios.patch(
          Notifications_BASE_URL, payload,
          {
            headers: {
              Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            },
          }
        );
        console.log("updateNotification", response);
        return { success: true };
      } catch (error) {
        console.error("error", error);
        return { success: false, error: error };
      }
    },
    deleteNotification: async () => {
      try {
        const response = await axios.delete(Notifications_BASE_URL, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          },
        });
        return { success: true,data:response.data };
      } catch (error) {
        console.error("error", error);
        return { success: false, error: error?.response?.data?.detail };
      }
    },
  };
  export default NotificationApi;