/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import StickyBox from "react-sticky-box";
import { Sidebar } from "../sidebar";
import Select from "react-select";
import { Link } from "react-router-dom";
import TokenManager from "../../../../Apis/TokenManager";
import AuthApi from "../../../../Apis/AuthApi";
import { toast } from "react-toastify";

const FreelancerVerifyIdentity = () => {
  const [user, setUser] = useState();
  const claims = TokenManager.getClaims(); 
console.log(user)
  const getUserDetails = async () => {
    const response = await AuthApi.getUser(claims.sub);
    if (response.success) {
      setUser(response.data);
    } else {
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    document.body.className = "dashboard-page";
    return () => {
      document.body.className = "";
    };
  });

  return (
    <>
      {/* Page Content */}
      <div className="content content-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <Sidebar />
              </StickyBox>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="dashboard-sec payout-section freelancer-statements">
                <div className="page-title portfolio-title">
                  <h3 className="mb-0">Verification Details</h3>
                </div>
                {user?.status === "Approved" ? (
                  <p>Your account is verified</p>
                ) : (
                  <>
                    {/* <div className="row">
                      <div className="col-lg-12">
                        <p>
                          upload any document that can help us to proof your
                          store.
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-block">
                          <label className="form-label">Document</label>
                          <div className="upload-sets">
                            <label className="upload-filesview">
                              Browse File
                              <input type="file" />
                            </label>
                            <h6>Or Drag &amp; Drop here</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-flex doc-btn">
                          <Link
                            to="#success-verified"
                            data-bs-toggle="modal"
                            className="btn btn-primary"
                          >
                            Submit Verification
                          </Link>
                        </div>
                      </div>
                    </div> */}
                    <p>Please wait untill the admin verifies your profile</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* The Modal */}
      <div
        className="modal fade success-modal hire-modal"
        id="success-verified"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body pt-4">
              <div className="success-msg-content text-center">
                <h4>Verification Submitted Successfully </h4>
                <p>
                  You will be verified once, admin approves your verification
                </p>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary mt-3"
                >
                  Okay
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /The Modal */}

      {/* /Page Content */}
    </>
  );
};
export default FreelancerVerifyIdentity;
