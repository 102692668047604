const getDateTime = {
  getDate: (timestamp) => {
    if (timestamp) {
      const formattedTimestamp = timestamp.split(".")[0] + "Z";
      const dateObj = new Date(formattedTimestamp);
      const date = dateObj.toISOString().split("T")[0];
      return date;
    }
  },
  getTime: (timestamp) => {
    const dateObj = new Date(timestamp);
    const time = dateObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return time;
  },
};

export default getDateTime;
