/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from "react";
import Select from "react-select";

export const PersonalInfo = ({ nextTab1,handleRegisterChange,cred }) => {
  const [date, setDate] = useState();

  // Handle file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // handleRegisterChange('img', file);
  };

  // Handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleRegisterChange(name, value);
  };

  return (
    <>
      <div className=" field-card select-account select-btn">
        <div>
          <div className="text-center onboard-head">
            <h2>Personal Info</h2>
            <p>
              Tell a bit about your bussiness. This information will appear on your
              public profile, so that other repair shops can get to know you
              better.
            </p>
          </div>
          <div className="field-item personal-info space-info">
            <form>
              <div className="row">
                {/* <div className="col-md-12 col-lg-12">
                  <div className="pro-form-img">
                    <div className="profile-pic">Profile Photo</div>
                    <div className="upload-files">
                      <label className="file-upload image-upbtn ">
                        <i className="feather-upload me-2" />
                        Upload <input
                          type="file"
                          onChange={handleFileChange}
                          className="d-none" // hide the default input
                        />
                      </label>
                      <span>
                        For better preview recommended size is 450px x 450px.
                        Max size 5mb.
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-6 col-lg-6">
                  <div className="input-block">
                    <label className="form-label">Bussiness Name *</label>
                    <input
                      type="text"
                      name="businessName"
                      className="form-control"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="input-block">
                    <label className="form-label">Email Address *</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      value={cred.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="input-block">
                    <label className="form-label">Phone Number *</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      className="form-control"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* <div className="col-md-6 col-lg-4">
                  <div className="input-block">
                    <label className="form-label">Date of Birth</label>
                    <DatePicker
                      selected={date}
                      onChange={handleChange}
                      className="form-control datetimepicker"
                    />
                  </div>
                </div> */}
                
                <div className="col-md-6 col-lg-6">
                  <div className="input-block">
                    <label className="form-label">Registration Number *</label>
                    <input
                      type="text"
                      name="KVK"
                      className="form-control"
                      onChange={handleInputChange}
                    />
                    <p>Company registration number (KVK,KBO,RCS,GAFI)</p>
                  </div>
                </div>
                {/* <div className="col-md-6 col-lg-4">
                  <div className="input-block">
                    <label className="form-label">Type of Job</label>
                    <Select
                      className="select"
                      options={options}
                      placeholder="Select"
                    />
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="input-block min-characters">
                    <label className="form-label">About your bussiness *</label>
                    <textarea
                      className="form-control"
                      name="description"
                      rows={5}
                      defaultValue=""
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="text-center">
          {/* <input
            className="btn btn-prev prev_btn btn-back"
            name="next"
            type="button"
            defaultValue="Back"
            onClick={prevTab}
          /> */} 
          <input
            className="btn next_btn btn-primary btn-get btn-next"
            name="next"
            type="button"
            defaultValue="Next"
            onClick={nextTab1}
          />
        </div>
      </div>
    </>
  );
};
export default PersonalInfo;
