/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import {
  Flags_ar,
  Flags_es,
  Flags_fr,
  Flags_nl,
  Flags_us,
  Logo_img,
} from "../imagepath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faChevronDown,
  faGear,
  faReceipt,
  faTimes,
  faUser,
  faBell,
  faTrash,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { default as logo } from "../../assets/img/Mokoto.png";
import TokenManager from "../../../Apis/TokenManager";
import NotificationHub from "../NotificationHub";
import NotificationApi from "../../../Apis/NotificationApi";
import AuthApi from "../../../Apis/AuthApi";
import { faUserTie  } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import emailjs from 'emailjs-com';
// eslint-disable-next-line no-unused-vars
const HeaderTwo = (props) => {
  const[contactEmail,setContactEmail]=useState("")
  const[contacMessage,setContactMessage]=useState("")
  const [isSideMenu, setSideMenu] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);

  const claims = TokenManager.getClaims();

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };

  const handleGetUserByUserId = async (id) => {
    return await AuthApi.getUser(id);
  };

  const getAllNotifications = async () => {
    try {
      const allNotifications = await NotificationApi.getAllNotifications();

      const notifics = await Promise.all(
        allNotifications?.response?.data?.map(async (notification) => {
          try {
            const notData = JSON.parse(notification.data);
            return {
              id: notification.id,
              type: notification.type,
              timestamp: notification.timestamp,
              status: notification.status,
              data: notData,
            };
          } catch (error) {
            console.error("Error fetching house:", error);
            return null;
          }
        })
      );

      setNotifications(
        notifics.filter((notification) => notification !== null)
      );
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  const formatTimestamp = (timestamp) => {
    const dateObj = new Date(timestamp);
    const date = dateObj.toISOString().split("T")[0];
    const time = dateObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return { date, time };
  };
  const handleDeleteAllNotifications = async () => {
    const ids = notifications?.map((not) => not.id) || [];
    if (ids.length > 0) {
      const response = await NotificationApi.deleteNotification(ids);
      if (response.success) {
        setNotifications([]);
      }
      console.log("delete response", response);
    }
  };
  const addNewNotification = (newNotification) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newNotification,
    ]);
  };
  const notificationsLabel = (count) => {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  };
  const handleOpenNotMenu = async () => {
    const ids = newNotifications?.map((not) => not.id) || [];
    if (ids.length > 0) {
      const response = await NotificationApi.updateNotification(ids);
      if (response.success) {
        getAllNotifications();
      }
    }
  };

  useEffect(() => {
    const newNot = notifications?.filter((not) => not.status === "New") || [];
    setNewNotifications(newNot);
  }, [notifications]);

  useEffect(() => {
    getAllNotifications();
  }, []);

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  let pathname = window.location.pathname;

  //nav transparent

  // eslint-disable-next-line no-unused-vars
  const [click, setClick] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const Reload2 = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 95) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);


  const handleSendEmail=(e)=>{
    e.preventDefault();
    if(contactEmail ===""){
      toast.error("please type your email")
    }
    if(contacMessage ===""){
      toast.error("please type your message")
    }

    const templateParams = {
      from_email: contactEmail,
      from_name: "Teach For Share", 
      message: contacMessage
    };

    emailjs.send(
      'service_a2jcj5f',    
      'template_yoxecy4',   
      templateParams,
      'NZuWdVImNrpgSVQLA'     
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setContactEmail("")
      setContactMessage("")
      toast.success("Message sent successfully!");
    }, (err) => {
      console.log('FAILED...', err);
      toast.error("Failed to send the message. Please try again.");
    });

  }
  return (
    <>
      {claims && (
        <NotificationHub
          setNotifications={addNewNotification}
          handlePlaySound={() => {}}
        />
      )}
      {/* Header */}
      <header className={`header header-two ${navbar ? "sticky" : ""} `}>
        <div className="top-header">
          <div className="container">
            <div className="top-head-items">
              <ul className="nav user-menu">
                {/* Flag */}
                <li className="nav-item dropdown has-arrow flag-nav">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    to="#"
                    role="button"
                  >
                    <img
                      src={Flags_us}
                      className="me-1"
                      alt="Flag"
                      height={20}
                    />{" "}
                    <span>English</span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link to="#" className="dropdown-item">
                      <img src={Flags_us} alt="Flag" height={16} /> English
                    </Link>
                    <Link to="#" className="dropdown-item">
                      <img src={Flags_nl} alt="Flag" height={16} /> Dutch
                    </Link>
                    <Link to="#" className="dropdown-item">
                      <img src={Flags_ar} alt="Flag" height={16} /> Arabic
                    </Link>
                  </div>
                </li>
                {/* /Flag */}
              </ul>
              <ul className="top-header-links">
                {/* <li>
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-linkedin-in" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-regular fa-bell" />
                      </Link>
                    </li>
                  </ul>
                </li> */}
                {!claims && (
                  <>
                    <li>
                      <Link to="/register">
                        <i className="feather feather-lock" />
                        Register
                      </Link>
                    </li>
                    <li>
                      <Link to="/login">
                        <i className="feather feather-user" />
                        Login
                      </Link>
                    </li>
                  </>
                )}
                {/* <li>
                  <Link to="/admin/login" target="_blank">
                    <i className="feather feather-user" />
                    Admin
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg header-nav p-0">
            <div className="navbar-header header-select">
              <Link id="mobile_btn" to="#" onClick={() => onHandleMobileMenu()}>
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to="/index" className="navbar-brand logo">
                <img src={logo} width={100} className="img-fluid" alt="Logo" />
              </Link>
              {/* <div className="input-group me-5">
                <Select
                  className="ms-4"
                  options={options}
                  placeholder="Categories"
                />
              </div> */}
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/" className="menu-logo">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="Logo"
                    width={100}
                  />
                </Link>

                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#"
                  onClick={() => onhandleCloseMenu()}
                >
                  <i>
                    <FontAwesomeIcon icon={faTimes} />
                  </i>
                </Link>
              </div>
              <ul className="main-nav main-two">
                <li
                  className={`has-submenu ${pathname === "/" ? "active" : ""}`}
                >
                  <Link
                    to="/"
                    className={isSideMenu === "home" ? "subdrop" : ""}
                  >
                    Home
                  </Link>
                </li>
                <li
                  className={` has-submenu ${
                    pathname === "/about" ? "active" : ""
                  }`}
                >
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#contactus"
                  >
                    Contact
                  </Link>
                </li>

                {claims && (
                  <>
                    <li
                      className={`has-submenu ${
                        pathname === "developer" ||
                        pathname === "developer-details" ||
                        pathname === "dashboard" ||
                        pathname === "manage-projects" ||
                        pathname === "favourites" ||
                        pathname === "invited-freelancer" ||
                        pathname === "membership-plans" ||
                        pathname === "milestones" ||
                        pathname === "chats" ||
                        pathname === "review" ||
                        pathname === "deposit-funds" ||
                        pathname === "withdraw-money" ||
                        pathname === "transaction-history" ||
                        pathname === "verify-identity" ||
                        pathname === "profile-settings" ||
                        pathname === "tasks" ||
                        pathname === "files" ||
                        pathname === "view-project-detail" ||
                        pathname === "project-payment"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className={
                          isSideMenu === "foremployers" ? "subdrop" : ""
                        }
                        onClick={() =>
                          toggleSidebar(
                            isSideMenu === "foremployers" ? "" : "foremployers"
                          )
                        }
                        onMouseEnter={() => setSideMenu("foremployers")}
                      >
                        <FontAwesomeIcon icon={faUser} /> Profile
                        <i>{/* <FontAwesomeIcon icon={faChevronDown} /> */}</i>
                      </Link>
                      {isSideMenu === "foremployers" ? (
                        <ul className="submenu">
                          <li
                            className={
                              pathname === "account-details" ? "active" : ""
                            }
                          >
                            <Link to="/account-details">
                              <FontAwesomeIcon icon={faAddressCard} />
                              My Details
                            </Link>
                          </li>
                          <li
                            className={
                              pathname === "membership" ? "active" : ""
                            }
                          >
                            <Link to="/membership">
                              <FontAwesomeIcon icon={faReceipt} /> Membership
                            </Link>
                          </li>
                          <li
                            className={
                              pathname === "membership" ? "active" : ""
                            }
                          >
                            <Link to="/chats">
                              <FontAwesomeIcon icon={faComments} /> Chats
                            </Link>
                          </li>
                          <li
                            className={pathname === "settings" ? "active" : ""}
                          >
                            <Link to="/settings">
                              <FontAwesomeIcon icon={faGear} /> Settings
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    {claims?.[
                      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                    ] === "GlobalAdmin" && (
                      <li>
                        <a href="/admin/users" style={{ color: "red" }}>
                          Admin
                        </a>
                      </li>
                    )}
                    <li>
                      <div>
                        <a
                          onClick={handleOpenNotMenu}
                          style={{ border: "none", marginTop: "17px" }}
                          className=""
                          href="#"
                          data-bs-toggle="dropdown"
                        >
                          <FontAwesomeIcon icon={faBell} size="xl" />
                          {newNotifications?.length > 0 && (
                            <span
                              style={{
                                fontSize: "12px",
                                padding: "2px",
                                color: "white",
                                backgroundColor: "#71D3A9",
                                borderRadius: "25%",
                                position: "absolute",
                                top: 5,
                                left: 10,
                              }}
                            >
                              {" "}
                              <div>{newNotifications?.length} </div>
                            </span>
                          )}
                        </a>
                        <div
                          className="dropdown-menu"
                          style={{ marginLeft: "-190px", minWidth: "235px" }}
                        >
                          <div className="not_menu_content">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "10px",
                              }}
                            >
                              <p>{notificationsLabel(notifications?.length)}</p>
                              {notifications?.length > 0 && (
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  onClick={handleDeleteAllNotifications}
                                  style={{
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                ></FontAwesomeIcon>
                              )}
                            </div>
                            <div
                              style={{
                                maxHeight: "270px",
                                overflowY: "auto",
                                padding: "10px",
                              }}
                            >
                              {notifications &&
                                notifications.length > 0 &&
                                notifications
                                  ?.slice()
                                  ?.reverse()
                                  ?.map((item, itemIndx) => {
                                    // const user = handleGetUserByUserId(item.data.UserId)
                                    return (
                                      <div
                                        key={itemIndx}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          gap: "20px",
                                        }}
                                      >
                                        <a
                                          href={
                                            item.type === "ConversationMessage"
                                              ? "chats"
                                              : item.type === "OfferComment"
                                                ? `post-details/${item.data.Id}`
                                                : `request-details/${item.data.Id}`
                                          }
                                          style={{
                                            width: "250px",
                                            fontSize: "16px",
                                            overflowWrap: "break-word",
                                          }}
                                        >
                                          {item.type === "ConversationMessage"
                                            ? `You have new message: ${item.data?.Text}`
                                            : `There is a new comment on ${item.data?.Title}`}
                                        </a>
                                        <p className="ms-auto text-gray">
                                          {formatTimestamp(item.timestamp).time}
                                        </p>
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* End avatar dropdown */}
                  </>
                )}
              </ul>
            </div>

            <ul className="nav header-navbar-rht">
              {claims && (
                <li>
                  <ul className="nav header-navbar-rht">
                    <li>
                      <Link to="/post-project" className="login-btn">
                        <i className="fa-solid fa-plus me-2"></i>Share Post{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/request-part" className="login-btn">
                        <i className="fa-solid fa-plus me-2"></i>Request Part{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </header>
      {/* /Header */}
      <div className="modal fade custom-modal" id="contactus">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body del-modal">
              <form>
                <div className="text-center pt-0 mb-5">
                <FontAwesomeIcon icon={faUserTie} size="2xl"/>
                  <h3>Contact us</h3>
                </div>
                {/* <p className="card-text">
                  Send us your message
                </p> */}
                <div className="row">
                <div className="col-lg-12">
                    <div className="input-block">
                      <label className="focus-label">
                        Email <span className="label-star"> *</span>
                      </label>
                      <div className="position-relative">
                        <input
                          type={"text"}
                          className="form-control floating pass-input1"
                          placeholder="type your email"
                          value={contactEmail}
                          onChange={(e) => setContactEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block">
                      <label className="focus-label">
                        Write your message{" "}
                        <span className="label-star"> *</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="How can we help you?"
                        value={contacMessage}
                        onChange={(e)=>setContactMessage(e.target.value)}
                        rows={5}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-section text-center">
                  <button type="button" onClick={handleSendEmail} className="btn btn-primary click-btn">
                  Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderTwo;

{
  /* <li className="nav-item dropdown">
<Link
  to="#"
  className="dropdown-toggle nav-link"
  data-bs-toggle="dropdown"
>
  <FeatherIcon icon="bell" />{" "}
  <span className="badge badge-pill">5</span>
</Link>
<div className="dropdown-menu notifications">
  <div className="topnav-dropdown-header">
    <span className="notification-title">Notifications</span>
    <Link to="#" className="clear-noti">
      {" "}
      Clear All
    </Link>
  </div>
  <div className="noti-content">
    <ul className="notification-list">
      <li className="notification-message">
        <Link to="#">
          <div className="media d-flex">
            <span className="avatar avatar-sm flex-shrink-0">
              <img
                className="avatar-img rounded-circle"
                alt=""
                src={avatar_02}
              />
            </span>
            <div className="media-body flex-grow-1">
              <p className="noti-details">
                <span className="noti-title">Brian Johnson</span>{" "}
                paid the invoice{" "}
                <span className="noti-title">#DF65485</span>
              </p>
              <p className="noti-time">
                <span className="notification-time">
                  4 mins ago
                </span>
              </p>
            </div>
          </div>
        </Link>
      </li>
      <li className="notification-message">
        <Link to="#">
          <div className="media d-flex">
            <span className="avatar avatar-sm flex-shrink-0">
              <img
                className="avatar-img rounded-circle"
                alt=""
                src={avatar_03}
              />
            </span>
            <div className="media-body flex-grow-1">
              <p className="noti-details">
                <span className="noti-title">Marie Canales</span>{" "}
                has accepted your estimate{" "}
                <span className="noti-title">#GTR458789</span>
              </p>
              <p className="noti-time">
                <span className="notification-time">
                  6 mins ago
                </span>
              </p>
            </div>
          </div>
        </Link>
      </li>
      <li className="notification-message">
        <Link to="#">
          <div className="media d-flex">
            <div className="avatar avatar-sm flex-shrink-0">
              <span className="avatar-title rounded-circle bg-primary-light">
                <i className="far fa-user" />
              </span>
            </div>
            <div className="media-body flex-grow-1">
              <p className="noti-details">
                <span className="noti-title">
                  New user registered
                </span>
              </p>
              <p className="noti-time">
                <span className="notification-time">
                  8 mins ago
                </span>
              </p>
            </div>
          </div>
        </Link>
      </li>
      <li className="notification-message">
        <Link to="#">
          <div className="media d-flex">
            <span className="avatar avatar-sm flex-shrink-0">
              <img
                className="avatar-img rounded-circle"
                alt=""
                src={avatar_04}
              />
            </span>
            <div className="media-body flex-grow-1">
              <p className="noti-details">
                <span className="noti-title">Barbara Moore</span>{" "}
                declined the invoice{" "}
                <span className="noti-title">#RDW026896</span>
              </p>
              <p className="noti-time">
                <span className="notification-time">
                  12 mins ago
                </span>
              </p>
            </div>
          </div>
        </Link>
      </li>
      <li className="notification-message">
        <Link to="#">
          <div className="media d-flex">
            <div className="avatar avatar-sm flex-shrink-0">
              <span className="avatar-title rounded-circle bg-info-light">
                <i className="far fa-comment" />
              </span>
            </div>
            <div className="media-body flex-grow-1">
              <p className="noti-details">
                <span className="noti-title">
                  You have received a new message
                </span>
              </p>
              <p className="noti-time">
                <span className="notification-time">
                  2 days ago
                </span>
              </p>
            </div>
          </div>
        </Link>
      </li>
    </ul>
  </div>
  <div className="topnav-dropdown-footer">
    <Link to="#">View all Notifications</Link>
  </div>
</div>
</li> */
}
