/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Header from '../../../commoncomponent/header';
import Sidebar from "../../../commoncomponent/sidebar";
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";
import Select from "react-select";
import SubCategoryApi from "../../../../Apis/SubCategoryApi";
import { toast } from "react-toastify";
import CategoriesApi from "../../../../Apis/CategoriesApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const SubCategory = () => {
  // const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [subCatergoryToAdd, setSubCategoryToAdd] = useState("");
  const [subCatergoryToDelete, setSubCategoryToDelete] = useState("");
  const [subCatergoryToUpdate, setSubCategoryToUpdate] = useState("");
  const [subCatergoryUpdate, setSubCategoryUpdate] = useState("");
  const [inputfilter, setInputfilter] = useState(true);
  const [selectedSize, setSelectedSize] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCat, setSelectedCat] = useState();
  const [selectedCatToAdd, setSelectedCatToAdd] = useState();

  const GetAllCategories = async () => {
    setLoading(true);
    const response = await CategoriesApi.getAllCategories();
    if (response.success) {
      setCategories(response.data);
      setSelectedCat({value:response.data[0].id,label:response.data[0].name});
      setSelectedCatToAdd({value:response.data[0].id,label:response.data[0].name});
    } else {
      toast.error("could not get categories");
    }
    setLoading(false);
  };

  const GetAllSubCategories = async () => {
    setLoading(true);
    const response = await SubCategoryApi.getAllSubCategoriesByCatId(
      selectedCat?.id || selectedCat?.value
    );
    if (response.success) {
      setSubCategories(response.data);
    } else {
      toast.error("could not get sub categories");
    }
    setLoading(false);
  };

  const deleteSubCategory = async () => {
    if (subCatergoryToDelete === "") {
      toast.error("please select sub category");
      return;
    }
    setLoading(true);
    const response =
      await SubCategoryApi.deleteSubCategory(subCatergoryToDelete);
    if (response.success) {
      window.location.reload();
      toast.success("sub category deleted successfully");
    }
    setLoading(false);
  };

  const addCategory = async () => {
    if (subCatergoryToAdd === "") {
      toast.error("please add sub category");
      return;
    }
    const payload = {
      categoryId: selectedCatToAdd.value,
      name: subCatergoryToAdd,
      description: subCatergoryToAdd,
    };
    setLoading(true);
    const response = await SubCategoryApi.addSubCategory(payload);
    if (response.success) {
      window.location.reload();
      toast.success("sub category added successfully");
    } else {
      toast.error("could not add sub category");
    }
    setLoading(false);
  };

  const updateCategory = async () => {
    const payload = {
      id: subCatergoryToUpdate,
      categoryId: selectedCat.value,
      name: subCatergoryUpdate,
      describtion: subCatergoryUpdate,
    };
    setLoading(true);
    const response = await SubCategoryApi.updateSubCategory(payload);
    if (response.success) {
      window.location.reload();
      toast.success("sub category updated successfully");
    } else {
      toast.error("could not update sub category");
    }
    setLoading(false);
  };

  useEffect(() => {
    GetAllCategories();
  }, []);

  useEffect(() => {
    if (selectedCat) {
      GetAllSubCategories();
    }
  }, [selectedCat]);

  // const handleChange = (date) => {
  //     setDate(date)
  // }
  const togglefilter = (value) => {
    setInputfilter(value);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Sub Category Name",
      dataIndex: "name",
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <div>
            <Link
              to="#"
              onClick={() => setSubCategoryToUpdate(text)}
              className="btn btn-sm btn-secondary me-2"
              data-bs-toggle="modal"
              data-bs-target="#edit-category"
            >
              <i className="far fa-edit" />
            </Link>
            <Link
              to="#"
              onClick={() => setSubCategoryToDelete(text)}
              className="btn btn-sm btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#delete_category"
            >
              <i className="far fa-trash-alt" />
            </Link>
          </div>
        </>
      ),
    },
  ];
  const options = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

    useEffect(() => {
    if (subCatergoryToUpdate) {
      setSubCategoryUpdate(
        subCategories.filter((Cat) => Cat.id === subCatergoryToUpdate)[0]?.name
      );
    }
  }, [subCatergoryToUpdate]);

  console.log(subCatergoryToUpdate);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* <Header /> */}
          <Sidebar />
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Sub Categories</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="fees">Categories</Link>
                  </li>
                  <li className="breadcrumb-item active">Sub Categories</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="#"
                  className="btn add-button me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#add-category"
                >
                  <i className="fas fa-plus" />
                </Link>
                {/* <Link className="btn filter-btn" to="#" id="filter_search">
                  <i
                    className="fas fa-filter"
                    onClick={() => togglefilter(!inputfilter)}
                  />
                </Link> */}
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Search Filter */}
          <div
            className="card filter-card"
            id="filter_inputs"
            style={{ display: inputfilter ? "block" : "none" }}
          >
            <div className="card-body pb-0">
              <form>
                <div className="row filter-row">
                  <div className="col-sm-4 col-md-4">
                    <div className="form-group">
                      <label>Categories</label>
                      <Select
                        className="select"
                        options={options}
                        value={selectedCat}
                        onChange={setSelectedCat}
                        placeholder="Select A  Category"
                      />
                      {/* <select className="form-control select">
                                                    <option>Select A Parent Category</option>
                                                    <option>Graphics &amp; Design</option>
                                                    <option>Graphics &amp; Design</option>
                                                </select> */}
                    </div>
                  </div>
                  {/* <div className="col-sm-4 col-md-4">
                    <div className="form-group">
                      <label>Sub Category Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        {loading ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
          {/* /Search Filter */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover mb-0 datatable">
                      <Table
                        pagination={{
                          total: subCategories.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        className="table"
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        dataSource={subCategories}
                        rowKey={(record) => record.id}
                      />
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Add Modal */}
            <div className="modal fade custom-modal" id="add-category">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  {/* Modal Header */}
                  <div className="modal-header">
                    <h4 className="modal-title">Add Sub Categories</h4>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  {/* Modal body */}
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>Select A Category</label>
                        {/* <Select2
                                                    className="select form-control"
                                                    data={options}
                                                    options={{
                                                        placeholder: 'Select A Parent Category',
                                                    }} /> */}
                        <Select
                          className="select"
                          value={selectedCatToAdd}
                          onChange={setSelectedCatToAdd}
                          options={options}
                          placeholder="Select A Parent Category"
                        />
                      </div>
                      <div className="form-group"> 
                        <label>Sub Category Name</label>
                        <input
                          type="text"
                          value={subCatergoryToAdd}
                          onChange={(e)=>setSubCategoryToAdd(e.target.value)}
                          className="form-control"
                          placeholder="Create a Sub Category"
                        />
                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          onClick={addCategory}
                          className="btn btn-primary btn-block"
                        >
                          {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Add Modal */}
            {/* Edit Modal */}
            <div className="modal fade custom-modal" id="edit-category">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  {/* Modal Header */}
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Sub Categories</h4>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  {/* Modal body */}
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>Select A Parent Category</label>
                        <Select
                          //   className="form-select"
                          value={selectedCatToAdd}
                          onChange={setSelectedCatToAdd}
                          options={options}
                          placeholder="Select A Parent Category"
                        />
                        {/* <Select2
                                                    className="select form-control"
                                                    data={options}
                                                    options={{
                                                        placeholder: 'Select A Parent Category',
                                                    }} /> */}
                        {/* <select className="form-select form-control"> */}
                        {/* <select
                                                    value={selectedSize}
                                                    defaultValue={"Graphics &amp; Design"}
                                                    onChange={(e) => setSelectedSize(e.target.value)}
                                                >
                                                    <option value={"Select A Parent Category"}>Select A Parent Category</option>
                                                    <option value={"Graphics &amp; Design"}>Graphics &amp; Design</option>
                                                    {/* <option selected="">Graphics &amp; Design</option> */}
                        {/* <option value={"Web Design"}>Web Design</option>
                                                </select>  */}
                        {/* <h1>You selected {selectedSize}</h1> */}
                      </div>
                      <div className="form-group">
                        <label>Sub Category Name</label>
                        <input 
                          type="text" 
                          value={subCatergoryUpdate}
                          onChange={(e)=>setSubCategoryUpdate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          onClick={updateCategory}
                          className="btn btn-primary btn-block"
                        >
                                                    {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Edit Modal */}
            {/* Delete Modal */}
            <div
              className="modal custom-modal fade"
              id="delete_category"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete</h3>
                      <p>Are you sure want to delete  {
                          subCategories.filter(
                            (Cat) => Cat.id === subCatergoryToDelete
                          )[0]?.name
                        }?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <Link to="#" onClick={deleteSubCategory} className="btn btn-primary continue-btn">
                          {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            "Delete" 
                          )}
                          </Link> 
                        </div>
                        <div className="col-6">
                          <Link
                            to="#"
                            data-bs-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Delete Modal */}
          </div>
        </div>
      </div>
    </>
  );
};
export default SubCategory;
