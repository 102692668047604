import axios from "axios";
import TokenManager from "./TokenManager";

const CONVERSATIONS_BASE_URL ="https://app-bunduk-techforshareapi-dev.azurewebsites.net/Conversations/"
const CONVERSATIONMSGS_BASE_URL ="https://app-bunduk-techforshareapi-dev.azurewebsites.net/ConversationMessages/"
const ConversationApi = {
    GetAllConversations: async () => {
      try {
        const response = await axios.get(
          CONVERSATIONS_BASE_URL + "userConversations" , {
            headers: {
              Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            },
          }
        );
  
        return { success: true, data: response.data.data };
      } catch (error) {
        return { success: false, error: error?.response?.data?.detail };
      }
    },
    GetConversationById: async (id) => {
      try {
        const response = await axios.get(CONVERSATIONS_BASE_URL + id, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          },
        });
  
        return { success: true, data: response.data };
      } catch (error) {
        return { success: false, error: error?.response?.statusText };
      }
    },
    DeleteConversation: async (id) => {
      try {
        const response = await axios.delete(CONVERSATIONS_BASE_URL + id+'&ExternalId=3fa85f64-5717-4562-b3fc-2c963f66afa6', {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          },
        });
  
        return { success: true, data: response.data.data };
      } catch (error) {
        return { success: false, error: error?.response?.statusText };
      }
    },
    GetConversationMessages: async (token, id) => {
  
      try {
        const payload = {
          ContinuationToken: token,
          ConversationId: id,
        };
        const response = await axios.get(CONVERSATIONMSGS_BASE_URL + '?ConversationId='+payload.ConversationId, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          },
        });
        return { success: true, data: response.data.data };
      } catch (error) {
        return { success: false, error: error?.response?.statusText };
      }
    },
    SendMessage: async (message) => {
      try {
        const response = await axios.post(CONVERSATIONMSGS_BASE_URL,message, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          },
        });
        return { success: true, data: response.data.data };
      } catch (error) {
        console.log(error);
        return { success: false, error: error?.response?.data?.detail };
      }
    },
    MessageSeen: async (id) => {
      try {
        const response = await axios.post(CONVERSATIONMSGS_BASE_URL + "markasseen", {
          conversationId:id
        }, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          },
        });
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    }
  };

  export default ConversationApi;