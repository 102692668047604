/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import TokenManager from "../../Apis/TokenManager";
import { useLocation } from "react-router-dom";
import { useConversation } from "../ConversationHub";

const NotificationHub = ({ setNotifications, handlePlaySound }) => {
  const location = useLocation();
  const[loc,setLoc]=useState()
  useEffect(()=>{
    setLoc(location.pathname)
  },[location])

  const {addMessage } = useConversation();

  useEffect(() => {
    let connection = null;

    const startConnection = () => {
      connection = new HubConnectionBuilder()
        .withUrl(
          `https://app-bunduk-techforshareapi-dev.azurewebsites.net/hubs/notificationhub?access_token=${TokenManager.getAccessToken()}`
        )
        .withAutomaticReconnect()
        .build();
      connection
        .start()
        .then((result) => {
          // console.log("Connected!");

          connection.on("NotificationReceived", (message) => {
            const messageData = {
              id: message.id.value,
              type: message.type,
              timestamp: message.timestamp,
              status: message.status,
              data: JSON.parse(message.data),
            };
            if (
              messageData.type === "ConversationMessage" 
            ) {
              console.log("messageData!",messageData);
              const msgData= {
                id:messageData.id,
                status:messageData.status,
                timestamp:messageData.timestamp,
                type:messageData.type,
                data:{
                  conversationId:messageData.data.ConversationId,
                  createdAt:messageData.data.CreatedAt,
                  id:messageData.data.Id,
                  senderId:messageData.data.SenderId,
                  status:messageData.data.Status,
                  text:messageData.data.Text
                }
              }
              addMessage(msgData)
            } else {
              setNotifications(messageData);
              handlePlaySound();
            }
          });
        })
        .catch((error) => {
          console.error("Connection failed: ", error);
        });
    };

    startConnection();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  return <div></div>;
};

export default NotificationHub;
