import React, { useEffect, useState } from "react";
import {useHistory,useLocation} from 'react-router-dom'
import PasswordApi from "../../../Apis/PasswordApi";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
 
const ResetPasswords = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get("id");
   const history=useHistory()

   const [loading,setLoading]=useState(false)
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [password1, setPassword1] = useState('');

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [password2, setPassword2] = useState('');

  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };
  useEffect(() => {
    document.body.className = 'account-page';
    return () => { document.body.className = ''; }
  });

  const handleResetPassword=async(e)=>{
    e.preventDefault()
    setLoading(true)
    if(password1!==password2){
        toast.error("passwords does not match")
        setLoading(false)
        return
    }
    if(password1==="" || password2===""){
        toast.error("Please fill in all fields")
        setLoading(false)
        return
    }
    const payload={
        newPassword:password1,
        token:id
    }
        const response = await PasswordApi.resetPassword(payload)
        if(response.success){
            toast.success("Password has been reset successfully")
            history.push('/')
        }else{
            toast.error("Could not reset password")
        }
        setLoading(false)
  }

  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  useEffect(() => {
    if (id) {
      if (!uuidRegex.test(id)) {
        history.push("/404");
      }
    }
  }, []);

  return (
    <>
      {/* Page Content */}
      <div className="login-wrapper">
        <div className="content w-100">
          <div className="container">
            {/* Login Content */}
            <div className="account-content">
              <div className="align-items-center justify-content-center">
                <div className="login-right">
                  <div className="login-header text-center">
                    <h2>Reset Your password</h2>
                    <span>Change Your Password</span>
                  </div>
                  <form>
                    <div className="input-block">
                      <label className="">New Password <span className="label-star"> *</span></label>
                      <div className="position-relative">
                        <input
                          type={passwordVisible1 ? 'text' : 'password'}
                          className="form-control floating pass-input"
                          value={password1}
                          onChange={(e) => setPassword1(e.target.value)}
                        />
                        <div className="password-icon" onClick={togglePasswordVisibility1}>
                          <span className={`fas toggle-password ${passwordVisible1 ? 'fa-eye' : 'fa-eye-slash'}`} />
                        </div>
                      </div>
                    </div>
                    <div className="input-block">
                    <label className="">Confirm New Password <span className="label-star"> *</span></label>
                    <div className="position-relative">
                      <input
                        type={passwordVisible2 ? 'text' : 'password'}
                        className="form-control floating pass-input"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                      />
                      <div className="password-icon" onClick={togglePasswordVisibility2}>
                        <span className={`fas toggle-password ${passwordVisible2  ? 'fa-eye' : 'fa-eye-slash'}`} />
                      </div>
                    </div> 
                  </div>
                    <button onClick={handleResetPassword} disabled={loading} className="btn btn-primary btn-lg login-btn d-flex align-items-center justify-content-center w-100" type="submit">
                    {loading ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                  <>Change Password <i className="feather-arrow-right ms-2" /></>
                                )}

                    </button>
                  </form>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
      {/* /Page Content */}

    </>
  )

}
export default ResetPasswords;