import axios from "axios";
import TokenManager from "./TokenManager";

const SWAGGER_SUBCATEGORY_URL =
  "https://app-bunduk-techforshareapi-dev.azurewebsites.net/SubCategories";

const SubCategoryApi = {
  getAllSubCategoriesByCatId: async (id) => {
    try {
      const response = await axios.get(SWAGGER_SUBCATEGORY_URL+"?categoryId="+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getSubCategoryById: async (id) => {
    try {
      const response = await axios.get(SWAGGER_SUBCATEGORY_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addSubCategory: async (payload) => {
    try {
      const response = await axios.post(SWAGGER_SUBCATEGORY_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  updateSubCategory: async (payload) => {
    try {
      const response = await axios.patch(SWAGGER_SUBCATEGORY_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  deleteSubCategory: async (id) => {
    try {
      const response = await axios.delete(SWAGGER_SUBCATEGORY_URL+"?id="+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default SubCategoryApi;
