import axios from "axios";
import TokenManager from "./TokenManager";

const SWAGGER_PASSWORD_URL = "https://app-bunduk-techforshareapi-dev.azurewebsites.net/Users"

const PasswordApi = {
  resetPassword: async (payload) => {
    try {
      const response = await axios.post(
        SWAGGER_PASSWORD_URL + "/resetpassword",
        payload,
        {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error) {
      return {
        success: false,
        error: error.response ? error.response.data : error.message,
      };
    }
  },
  forgetPassword: async (email) => {
    try {
      const response = await axios.post(
        SWAGGER_PASSWORD_URL + "/forgetpassword",
        email,
        {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error) {
      return {
        success: false,
        error: error.response ? error.response.data : error.message,
      };
    }
  },
};

export default PasswordApi;
