import axios from "axios";
import TokenManager from "./TokenManager";

const SWAGGER_TECHOFFERS_URL =
  "https://app-bunduk-techforshareapi-dev.azurewebsites.net/TechOffers";

const TechOffers = {
  getAllTechOffers: async () => {
    try {
      const response = await axios.get(SWAGGER_TECHOFFERS_URL, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getUserTechOffers: async () => {
    try {
      const response = await axios.get(SWAGGER_TECHOFFERS_URL+"/usertechoffers", {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getTechOfferByUserId: async (id) => {
    try {
      const response = await axios.get(SWAGGER_TECHOFFERS_URL+"?userId="+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getTechOffer: async (id) => {
    try {
      const response = await axios.get(SWAGGER_TECHOFFERS_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addTechOffer: async (payload) => {
    try {
      const response = await axios.post(SWAGGER_TECHOFFERS_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  updateTechOffer: async (payload) => {
    try {
      const response = await axios.put(SWAGGER_TECHOFFERS_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  deleteTechOffer: async (id) => {
    try {
      const response = await axios.delete(SWAGGER_TECHOFFERS_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addTechOfferComment: async (payload) => {
    try {
      const response = await axios.post(SWAGGER_TECHOFFERS_URL+"/addtechoffercomment",payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  removeTechOfferComment: async (payload) => {
    try {
      const response = await axios.post(SWAGGER_TECHOFFERS_URL+"/removetechoffercomment",payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default TechOffers;
